import React, { CSSProperties, ReactNode, useState } from 'react';

import { Button } from '@rugby-au/button';
import { ITheme, useTheme } from '@rugby-au/theme';
import { View, Pressable, ScrollView } from 'react-native';
import { Icons, IconTypes } from '@rugby-au/icons';
import { useAppConfig } from '@rugby-au/app-config';
import { Text } from '@rugby-au/commons';

import { NavItemProps } from './WebHeader';
import { getNavItemStyles, getCartNavIconStyles, getCartSubNavStyles } from './styles';
import { BillItem } from '@rugby-au/payments';
import { CountdownNavItem } from './CountDownNavItem';

export const NavItem = ({
  appearance = 'light',
  subNavAppearance = 'primary',
  navItem,
  index,
  isMobile = false,
  isCart = false,
  isCountDown = false,
  onClick,
}: {
  appearance: ITheme;
  subNavAppearance: ITheme;
  navItem: NavItemProps;
  index: number;
  isMobile: boolean;
  isCart?: boolean;
  isCountDown?: boolean;
  onClick?: () => void;
}) => {
  const { spacing, colorPairs, typography, radius } = useTheme();
  const [isHover, setIsHover] = useState<null | boolean>(null);

  const colors = colorPairs[appearance];
  const subNavColors = colorPairs[subNavAppearance];

  const styles = getNavItemStyles({ spacing, colors, typography, subNavColors, isHover, isButton: !!navItem.button });

  let linkBody: ReactNode = navItem.title;
  if (isCart) {
    linkBody = <CartNavIcon iconColor={isHover ? colors.background.mid : colors.foreground.mid} isHover={isHover} appearance={appearance} isMobile={isMobile} />;
  } else if (isCountDown) {
    linkBody = <CountdownNavItem isHover={isHover} appearance={appearance} isMobile={isMobile} />;
  } else if (navItem.icon && navItem.href) {
    linkBody = <Icons width={20} color={isHover ? colors.background.mid : colors.foreground.mid} type={navItem.icon as IconTypes} />;
  } else if (navItem.button) {
    linkBody = <Button {...{ title: navItem.button.text, appearance: navItem.button.appearance, borderRadius: radius.small, size: isMobile ? 'small' : 'medium' }} />;
  }

  return (
    <Pressable onHoverIn={() => setIsHover(true)} onHoverOut={() => setIsHover(false)} style={styles.container} key={`primary-nav-navItem-${index}`}>
      <li>
        <a
          {...(onClick ? { onClick: onClick } : { href: navItem.href })}
          style={{ ...styles.linkContainer, padding: navItem.button ? 0 : isMobile ? spacing.small : spacing.large, display: 'inline-block' } as CSSProperties}>
          {linkBody}
        </a>
        {navItem?.subNav?.navItems && (
          <View style={styles.subNavContainer}>
            {navItem?.subNav?.navItems?.map((subItem, s) => (
              <View key={`primary-nav-sub-navItem-${index}-${s}`}>
                <a href={subItem.href} style={styles.subNavLink as CSSProperties}>
                  <span style={styles.subNavText as CSSProperties}>{subItem.title}</span>
                </a>
              </View>
            ))}
          </View>
        )}
        {isCart && <CartSubNav isHover={isHover} subNavAppearance={subNavAppearance} />}
      </li>
    </Pressable>
  );
};

const CartNavIcon = ({ iconColor, isHover, appearance, isMobile }: { iconColor: string; isHover: boolean | null; appearance: ITheme; isMobile: boolean }) => {
  const { cart } = useAppConfig();
  const { spacing, colorPairs, colors, typography, radius, sizes } = useTheme();

  const navColors = colorPairs[appearance];

  const styles = getCartNavIconStyles({ spacing, colors, navColors, typography, isMobile, radius, size: sizes.xsmall });

  return (
    <div>
      <Icons width={20} color={iconColor} type={'cart'} />
      {cart && cart.items && cart.items.length > 0 && !isHover && (
        <View style={styles.cartCountContainer}>
          <Text style={styles.cartCountText}>{cart.items.length > 9 ? '9+' : cart.items.length}</Text>
        </View>
      )}
    </div>
  );
};

const CartSubNav = ({ isHover, subNavAppearance }: { isHover: boolean | null; subNavAppearance: ITheme }) => {
  const { cart, setNavigation, setMultiFormLayout } = useAppConfig();
  const { spacing, colorPairs } = useTheme();

  const subNavColors = colorPairs[subNavAppearance];

  const styles = getCartSubNavStyles({ spacing, subNavColors, isHover: !!isHover });

  return cart && cart.items && cart.items.length > 0 && isHover ? (
    <View style={styles.cartSubNav}>
      <ScrollView>
        {cart.items.map((item: any, index: number) => (
          <BillItem
            key={index + item.id}
            appearance={subNavAppearance}
            name={item.name}
            detail={item.types.map((type: any) => ({ name: type.name, qty: type.quantity }))}
            onEdit={() => {
              setNavigation && setNavigation(`/checkout?productId=${item.id}`);
              setMultiFormLayout('addProductLayout');
            }}
          />
        ))}
      </ScrollView>
    </View>
  ) : (
    <></>
  );
};
