import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { IColorGroup, ISizeGroup, ISpacing } from '@rugby-au/theme';

export const buttonWeightValues = ['regular', 'bold', 'subtle', 'underline', 'none'] as const;
export const buttonSizeValues = ['small', 'medium', 'large'] as const;

export type Weight = typeof buttonWeightValues[number];
export type Size = typeof buttonSizeValues[number];

export const getButtonStyles = ({
  colors,
  spacing,
  weight,
  size,
  disabled,
  fullWidth,
  transparentBackground,
  borderRadius,
  isLoading,
}: {
  colors: IColorGroup;
  spacing: ISpacing;
  size?: ISizeGroup;
  weight?: Weight;
  disabled: boolean;
  fullWidth: boolean;
  transparentBackground: boolean;
  borderRadius: number;
  isLoading: boolean;
}) => {
  let baseContainer: ViewStyle | TextStyle = {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: size?.paddingY,
    paddingLeft: size?.paddingX,
    paddingBottom: size?.paddingY,
    paddingRight: size?.paddingX,
    textAlign: 'center',
    backgroundColor: colors.background.mid,
    color: colors.foreground.mid,
    borderWidth: spacing.px,
    borderColor: colors.background.mid,
    borderRadius: borderRadius,
    alignSelf: 'flex-start',
  };

  if (weight === 'regular') {
    baseContainer = { ...baseContainer, backgroundColor: 'transparent', borderColor: colors.background.mid };
  } else if (weight === 'subtle') {
    baseContainer = { ...baseContainer, backgroundColor: 'transparent', borderColor: 'transparent' };
  } else if (weight === 'none' || weight === 'underline') {
    baseContainer = {
      ...baseContainer,
      backgroundColor: 'transparent',
      color: colors.background.mid,
      borderWidth: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: 0,
      paddingRight: 0,
    };
  }

  if (disabled) {
    baseContainer = {
      ...baseContainer,
      borderColor: disabled ? colors.background?.light : colors.background?.light,
      backgroundColor: disabled ? colors.background?.light : colors.background?.light,
      color: disabled ? colors?.neutral?.mid : colors.background?.light,
      opacity: disabled ? 0.7 : 1,
    };
  }

  if (fullWidth) {
    baseContainer = { ...baseContainer, alignSelf: 'stretch' };
  }

  if (transparentBackground) {
    baseContainer = { ...baseContainer, borderColor: 'transparent', backgroundColor: 'rgba(255,255,255,0.3)' };
  }
  return StyleSheet.create({
    container: baseContainer,
    text: {
      color: isLoading ? 'transparent' : weight === 'bold' ? colors.foreground.mid : colors.background.mid,
      fontSize: size?.fontSizeText,
      textDecorationLine: weight === 'subtle' || weight === 'underline' ? 'underline' : 'none',
    } as TextStyle,
  });
};
