import { ISiteColors, ISpacing, ITypography } from '@rugby-au/theme';
import { StyleSheet } from 'react-native';

export const getContainerStyles = ({
  // duration,
  severity,
  spacing,
  typography,
  colors,
}: {
  duration: number;
  severity: string;
  spacing: ISpacing;
  typography: ITypography;
  colors: ISiteColors;
}) => {
  return StyleSheet.create({
    container: {
      // transition: `top ${duration}ms ease-in-out`,
      position: 'absolute' as 'absolute',
      //opacity: 0,
      color: colors.primaryText,
      width: '100%',
      zIndex: 1000,
      paddingVertical: spacing.xlarge,
      paddingHorizontal: spacing['2xlarge'],
      fontSize: typography.fontSize.small,
      textAlign: 'left',
      backgroundColor: severity === 'error' ? '#E11900' : '#00A650',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  });
};

export const getButtonStyles = ({ spacing, colors }: { spacing: ISpacing; colors: ISiteColors }) => {
  return StyleSheet.create({
    button: {
      padding: spacing.small,
      backgroundColor: colors.background,
      borderRadius: spacing.xsmall,
    },
    buttonText: {
      color: colors.primaryText,
      fontWeight: 'bold',
    },
  });
};
