import React, { forwardRef, useState } from 'react';
import { View } from 'react-native';

import { Icons } from '@rugby-au/icons';
import { useTheme } from '@rugby-au/theme';
import { Text } from '@rugby-au/commons';
import { usePropsWithoutFunctions } from './utils';

export const TextInputFieldWrapper = forwardRef(
  (
    {
      handleOnChange,
      handleOnKeyDown,
      value,
      type,
      autoCompleteTypeWeb,
      fieldContainerStyles,
      icon,
      iconAlignment,
      isValid,
      isError,
      inputStyles,
      dataMediaContainer,
      dataMediaInput,
      ...props
    }: any,
    ref,
  ) => {
    const { colors, typography } = useTheme();

    const [isActive, setIsActive] = useState(false);

    const cleanProps = usePropsWithoutFunctions(props);

    let iconWidth = 0;
    if (icon) {
      iconWidth = 10;
    }
    if (isValid || isError) {
      iconWidth += 10;
    }

    return (
      <View style={{ ...fieldContainerStyles, ...(isActive ? { outline: '-webkit-focus-ring-color auto 1px' } : {}) }} dataSet={{ media: dataMediaContainer }}>
        {icon && iconAlignment === 'left' && (
          //@ts-ignore
          <View style={{ height: '15px', width: '10%', paddingRight: '1%' }}>
            <Icons type={icon} color={colors.neutral} />
          </View>
        )}
        {props.prefix && (
          <View>
            <Text>{props.prefix}</Text>
          </View>
        )}
        <input
          style={{
            ...inputStyles,
            width: `${100 - iconWidth}%`,
            border: 'none',
            outline: 'none',
            backgroundColor: fieldContainerStyles?.backgroundColor,
            fontSize: typography.fontSize.large,
          }}
          onChange={event => {
            handleOnChange && handleOnChange(event.target.value);
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              //trigger submit
              props.onSubmitEditing && props.onSubmitEditing();
            }
            handleOnKeyDown && handleOnKeyDown(event);
          }}
          value={value}
          // id={props.key}
          // name={props.key}
          data-media={dataMediaInput}
          {...cleanProps}
          //keyboardType={type}
          type={type}
          autoComplete={autoCompleteTypeWeb}
          clearButtonMode={'always'}
          //blurOnSubmit={true}
          //onBlur={() => checkValue(inputValue)}
          // onSubmtEditing{checkValue}
          onFocus={() => {
            setIsActive(true);
          }}
          onBlur={e => {
            if (props.onBlur) {
              props.onBlur(e);
            }
            setIsActive(false);
          }}
          ref={ref}
        />
        {isValid && (
          //@ts-ignore

          <View style={{ height: '15px', width: '10%' }}>
            <Icons type={'check'} color={colors.green} />
          </View>
        )}
        {isError && (
          //@ts-ignore

          <View style={{ height: '15px', width: '10%' }}>
            <Icons type={'error'} color={colors.danger} />
          </View>
        )}
        {icon && iconAlignment === 'right' && (
          //@ts-ignore

          <View style={{ height: '15px', width: '10%' }}>
            <Icons type={icon} color={colors.neutral} />
          </View>
        )}
      </View>
    );
  },
);
