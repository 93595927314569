import { SamlRequest } from '@rugby-au/saml-form';
import { get } from './API';
import { loadBasicAuthHeaders } from './utils';

export interface ClientAuthProps {
  username: string;
  name?: string;
  codeChallenge: string;
  redirectUrl?: string;
  redirectPath?: string;
  authCode?: string;
  ssoGroup?: string;
  noPkce?: boolean;
  SAMLRequest?: SamlRequest;
}

export type ClientUIConfigProps = {
  title: string;
  primaryColour: string;
  secondaryColour: string;
  loginBanner: string;
  loginForeground: string;
  logo: string;
};

export const getClientAuthProps = async ({
  username,
  redirectUrl,
  redirectPath,
  codeChallenge,
}: {
  username: string;
  redirectUrl: string;
  redirectPath: string;
  codeChallenge: string;
}) => {
  if (!redirectUrl && !redirectPath) {
    redirectPath = '/';
  }
  const client = await get({ path: 'rau/api/v1/apitokens/', params: { username, redirectUrl, redirectPath, codeChallenge }, cached: true, cachedKey: username });
  return client;
};

export const getClientUIConfig = async ({ username }: { username: string }) => {
  const headers = loadBasicAuthHeaders(`${process.env.NEXT_PUBLIC_API_USERNAME}:${process.env.RX_API_SERVER_TOKEN}`);
  const client = (await get({ path: 'rau/api/v1/auth-client/ui-config', params: { clientId: username }, headers, cached: true, cachedKey: username })) as
    | ClientUIConfigProps
    | Error;
  return client;
};

// export const setClientAuthProps = async (id: string, name: string, redirectUrl: string) => {
//   client = {id, name, redirectUrl};
// }; $^$||
