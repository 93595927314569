import { useAppConfig } from '@rugby-au/app-config';
import { getCurrentProfile } from '@rugby-au/business';
import { Button } from '@rugby-au/button';
import { FieldRefMethodProps, TextInputField } from '@rugby-au/form-fields';
import { useTheme } from '@rugby-au/theme';
import { Text } from '@rugby-au/commons';

import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import { PaymentRefProps } from './Payment';

declare global {
  interface Window {
    Pin: any;
  }
}

export const PinPayment = ({ displayButton = true, paymentRef }: { displayButton: boolean; paymentRef?: MutableRefObject<PaymentRefProps | null> }) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);
  const ccNumberRef = useRef<FieldRefMethodProps>(null);
  const ccNameRef = useRef<FieldRefMethodProps>(null);
  const ccExpRef = useRef<FieldRefMethodProps>(null);
  const ccCVCRef = useRef<FieldRefMethodProps>(null);
  const pinApiRef = useRef<any>(null);

  const { colors, spacing, typography } = useTheme();
  const { setNotification } = useAppConfig();

  useEffect(() => {
    const pinApi = new window.Pin.Api(process.env.NEXT_PUBLIC_PIN_PK, process.env.NEXT_PUBLIC_PIN_ENV);
    pinApiRef.current = { pinApi };
  }, []);

  const handleOnPay = useCallback(async () => {
    //reset errors
    setError(null);
    ccNumberRef.current?.setError?.('');
    ccNameRef.current?.setError?.('');
    ccExpRef.current?.setError?.('');

    setLoading(true);

    if (ccNumberRef.current?.value === '' || ccNumberRef.current?.value.length < 0) {
      setError('Invalid Card Number');
      setLoading(false);

      return;
    }

    if (ccNameRef.current?.value === '') {
      setError('Invalid Card Name');
      setLoading(false);

      return;
    }
    if (ccCVCRef.current?.value === '' || ccCVCRef.current?.value.length < 3) {
      setError('Invalid CVC');
      setLoading(false);

      return;
    }
    // Check valid expiry year should be 2 digits
    const expiry = ccExpRef.current?.value.replace(/\s/g, '').split('/');
    if (expiry.length !== 2 || expiry[1].length !== 2) {
      setError('Card expiry year must be 2 digits, valid card expiry example: 11 / 20');
      return;
    }

    try {
      const _profile = await getCurrentProfile();

      if (!_profile.address) {
        setError('Address is required');
        setLoading(false);
        return;
      }
      const card = {
        number: ccNumberRef.current?.value,
        name: ccNameRef.current?.value,
        expiry_month: expiry[0],
        expiry_year: expiry[1],
        cvc: ccCVCRef.current?.value,
        address_line1: _profile.address.addressline1,
        address_line2: _profile.address.addressline2 || '',
        address_city: _profile.address.city,
        address_state: _profile.address.state,
        address_postcode: _profile.address.postcode,
        address_country: _profile.address.country,
      };
      const response = await pinApiRef?.current?.pinApi.createCardToken(card);
      // Block International Card === "american_express"
      if (response.scheme !== 'master' && response.scheme !== 'visa') {
        setError('Payment method not supported');
        setLoading(false);
      } else {
        return response.token as string;
      }
    } catch (e: any) {
      console.log('PinPayment.tsx line 91 - e ', e);
      setError(e.error_description);
      setNotification({ message: e.error_description, severity: 'error' });
      setFieldErrors(e.messages);
      setLoading(false);
      return;
    }
  }, [setNotification]);

  useEffect(() => {
    if (paymentRef) {
      paymentRef.current = {
        triggerPayment: handleOnPay,
      };
    }
  }, [paymentRef, handleOnPay]);

  const setFieldErrors = (messages: { param: string; code: string; message: string }[]) => {
    messages?.forEach(message => {
      if (message.param === 'number') {
        ccNumberRef.current?.setError?.(message.message);
      }
      if (message.param === 'name') {
        ccNameRef.current?.setError?.(message.message);
      }
      if (message.param === 'expiry_month' || message.param === 'expiry_year') {
        ccExpRef.current?.setError?.(message.message);
      }
      if (message.param === 'cvc') {
        ccCVCRef.current?.setError?.(message.message);
      }
    });
  };

  return (
    <View style={{ flex: 1, padding: spacing.medium }}>
      <View>
        <View style={{ paddingBottom: spacing.large }}>
          <TextInputField
            fieldRef={ccNumberRef}
            mask={(value: string) => {
              const cardValue = value.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
              const displayValue = cardValue
                ? !cardValue[2]
                  ? cardValue[1]
                  : `${cardValue[1]}-${cardValue[2]}${`${cardValue[3] ? `-${cardValue[3]}` : ''}`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`
                : '';
              const actualValue = displayValue.replace(/(\D)/g, '');
              return { displayValue, actualValue };
            }}
            required
            placeholder={'Credit Number'}
            onChangeField={() => {}}
            icon={'padlock'}
            autoCompleteTypeWeb={'cc-number'}
          />
        </View>
        <View style={{ paddingBottom: spacing.large }}>
          <TextInputField onChangeField={() => {}} fieldRef={ccNameRef} placeholder={'Name on Card'} required autoCompleteTypeWeb={'cc-name'} />
        </View>
      </View>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View style={{ flex: 1, paddingRight: spacing.medium, paddingBottom: spacing.large }}>
          <TextInputField
            fieldRef={ccExpRef}
            placeholder={'Expiration Date (MM/YY)'}
            mask={value => {
              const expValue = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})/);
              const displayValue = expValue ? (!expValue[2] ? expValue[1] : `${expValue[1]}${`${expValue[2] ? `/${expValue[2]}` : ''}`}`) : '';
              // const actualValue = displayValue.replace(/(\D)/g, '');
              return { displayValue, actualValue: displayValue };
            }}
            onChangeField={() => {}}
            autoCompleteTypeWeb={'cc-exp'}
          />
        </View>
        <View style={{ flex: 1, paddingLeft: spacing.medium, paddingBottom: spacing.large }}>
          <TextInputField onChangeField={() => {}} fieldRef={ccCVCRef} placeholder={'Security Code'} maxLength={3} autoCompleteTypeWeb={'cc-csc'} />
        </View>
      </View>
      {error && error !== '' && (
        <View style={{ paddingBottom: spacing.large }}>
          <Text style={{ color: colors.danger, fontFamily: typography.fontFamily.regular, fontSize: typography.fontSize.medium }}>{error}</Text>
        </View>
      )}
      {displayButton && (
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <View>
            <Button
              onPress={() => {
                handleOnPay();
              }}
              title={'Pay'}
              isLoading={isLoading}
            />
          </View>
        </View>
      )}
    </View>
  );
};
