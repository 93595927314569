export const wallabiesHeader = {
  siteLogo: {
    alt: 'horizontal white',
    url: 'https://d26phqdbpt0w91.cloudfront.net/NonVideo/71a0f2dd-5c78-4bdc-9306-e7421107ad67.png',
    // tags: ['wallabies  bmw  u20s'],
    // damId: '71a0f2dd-5c78-4bdc-9306-e7421107ad67',
    // title: 'Wallabies White Logo',
    width: 1158,
    height: 320,
    // tagGroups: [],
    source: {
      height: 1,
      width: 1,
      scale: 1,
      uri: 'https://d26phqdbpt0w91.cloudfront.net/NonVideo/71a0f2dd-5c78-4bdc-9306-e7421107ad67.png',
    },
  },
  globalNav: { navItems: [], appearance: 'primary' as const },
  appearance: 'primary' as const,
  primaryNav: {
    navItems: [
      {
        href: '/fixtures-results?comp=All&team=100&tab=Fixtures',
        title: 'Fixtures',
      },
      {
        href: '/',
        title: 'Membership',
        stopTranform: true,
      },
      {
        href: '',
        title: 'Tickets',
        subNav: {
          navItems: [
            // { href: '/membership', title: 'Membership' },
            {
              href: 'https://wallabiestravel.com.au/',
              title: 'Travel',
            },
            {
              href: '/experience/hospitality',
              title: 'Hospitality',
            },
          ],
          appearance: 'hero' as const,
        },
      },
      { href: '/squad/wallabies', title: 'Squad' },
      {
        href: '#',
        title: 'News',
        subNav: {
          navItems: [
            { href: '/news', title: 'Latest News' },
            { href: '/videos', title: 'Videos' },
          ],
          appearance: 'hero' as const,
        },
      },
      { href: 'https://wallabyshop.com.au/', title: 'Shop' },
      {
        href: '#',
        title: 'About',
        subNav: {
          navItems: [
            { href: '/about/about-us', title: 'About Us' },
            {
              href: '/about/about-us/our-history',
              title: 'Our History',
            },
            {
              href: '/about/wally-the-wallaby',
              title: 'Wally the Wallaby',
            },
            {
              href: '/about/fan-hub-and-events',
              title: 'Fan Hub & Events',
            },
            { href: '/about/partners', title: 'Partners' },
          ],
          appearance: 'hero' as const,
        },
      },
      { href: '/join', title: 'Subscribe' },
    ],
    appearance: 'primary' as const,
  },
  quickAccessNav: { navItems: [], appearance: 'primary' as const, displayCart: true, displayUserOptions: true },
  deviceNavAppearance: 'primary' as const,
};
