import React from 'react';
import { View } from 'react-native';
import DatePicker from 'react-datepicker';

import MaskedTextInput from 'react-text-mask';
// import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import { DatePickerFieldProps } from './DatePickerField';
// import { enAU } from 'date-fns/locale';
// import { registerLocale, setDefaultLocale } from 'react-datepicker';
// registerLocale('en-AU', enAU);
// console.log('DatePicker.tsx line 11 - enAU ', enAU);

export interface DatePickerProps extends DatePickerFieldProps {
  /** Styles loaded from the main component */
  styles?: any;
  inputValue: any;
  hasChanged: any;
  displayValue: any;
  isValid: boolean;
  isError: boolean;
  dateformat: any;
  dataSet: any;
  fieldWrapperStyles: any;
  handleOnChange: (value: any) => void;
}

export const DatePickerWrapper = ({ styles, inputValue, dateformat, handleOnChange }: DatePickerProps) => {
  const value = inputValue ? moment(inputValue, ['DD/MM/YYYY', 'DD-MM-YYYY', 'MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD']).toDate() : null;
  return (
    <>
      <View>
        <DatePicker
          className="date-form-control"
          dateFormat={dateformat}
          //@ts-ignore;
          styles={styles.fieldContainer}
          selected={value}
          onChange={(e: any) => handleOnChange(e)}
          // dataMedia={props.dataSet}
          showMonthDropdown
          showYearDropdown
          customInput={
            // <View style={styles.fieldContainer}>
            <MaskedTextInput style={styles.fieldContainer} type="text" mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} />
            // </View>
          }
        />
        {/* <input
          type="date"
          style={styles.fieldContainer}
          value={inputValue}
          onChange={e => handleOnChange(e.target.value)}
          min="1900-01-01"
          max="2099-12-31"
          lang="en-US"
          placeholder="mm/dd/yyyy"
        /> */}
      </View>
    </>
  );
};
