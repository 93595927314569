import { AppConfigProps } from '@rugby-au/app-config';
import { FieldActionProps } from './type';

/**
 *  Method to handle action when triggeres from the form field
 * @param param0 action type and key, value - field value, appConfig - app config object
 */
export const onUpdateAction = ({
  action,
  value,
  appConfig,
}: {
  action?: FieldActionProps;
  value: string | number | boolean | { [key: string]: string } | any;
  appConfig: AppConfigProps;
}) => {
  if (value !== undefined) {
    switch (action?.type) {
      case 'appConfigRegion':
        // appConfig.setNationalId(
        //   typeof action?.valueKey === 'string' && action?.valueKey?.includes('.')
        //     ? action?.valueKey?.split('.').reduce((a, b) => a[b], value as any)
        //     : action.valueKey
        //     ? (value as any)[action.valueKey]
        //     : (value as string),
        // );
        appConfig.setNationalId(
          action?.valueKey === 'objectKey' && typeof action.keyIndex === 'number' && Object.keys(value.value).length >= action.keyIndex
            ? Object.keys(value.value)[action.keyIndex as number]
            : action.valueKey
            ? (value as any)[action.valueKey]
            : (value as string),
        );
        break;

      default:
        null;
    }
  }
};
