import React, { ReactNode, useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { useTheme } from '@rugby-au/theme';
import { Text } from '@rugby-au/commons';

import { CheckboxFieldWrapper, CustomLabel } from './Checkbox';
import { getCheckboxInputStyles } from './styles';
import { FieldRefProps } from './types';
import { Container } from '@rugby-au/container';

// import {FormStyleProps, getFormStyles} from './styles';

export interface CheckboxFieldProps {
  /** Label */
  label?: string | ReactNode;
  /** selected value */
  value?: boolean;
  /** is disabled */
  disabled?: boolean;
  /** invoke in on change */
  onChangeField?: (value: boolean) => void;
  /** Status - Prechecked or not */
  // defaultValue?: boolean;
  /** Label Styles */
  labelStyles?: any;
  /** Field Styles */
  fieldStyles?: any;
  /** HTML Label i.e checkbox label with a link */
  htmlLabel?: string;
  /** align */
  align?: 'left' | 'right';
  /** Is Required */
  required?: boolean;
  /**ref with check method */
  fieldRef?: FieldRefProps;
}

export const CheckboxField = ({
  label,
  value,
  disabled = false,
  onChangeField,
  // defaultValue = false,
  labelStyles,
  fieldStyles,
  htmlLabel,
  align = 'left',
  required = false,
  fieldRef,
  ...props
}: CheckboxFieldProps) => {
  const [toggleCheckBox, setToggleCheckBox] = useState(value);
  const [isError, setIsError] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const { colors, spacing, typography } = useTheme();

  // Update checkbox value and trigger onChangeField
  useEffect(() => {
    if (value !== toggleCheckBox && toggleCheckBox && onChangeField) {
      onChangeField(toggleCheckBox);
    }
  }, [toggleCheckBox, value, onChangeField]);

  useEffect(() => {
    if (value !== toggleCheckBox && !hasChanged) {
      setToggleCheckBox(value);
    }
  }, [hasChanged, toggleCheckBox, value]);

  // Get checkbox styles
  const { ids, styles } = getCheckboxInputStyles({ colors, spacing, align, typography });

  // Label container
  const labelContainer = htmlLabel ? (
    <View style={styles.labelContainer}>
      <CustomLabel
        htmlLabel={htmlLabel}
        dataMedia={ids.label}
        tagsStyles={{ ...styles.label, color: colors.neutral, '> a': { color: colors.neutral, textDecorationColor: colors.neutral } }}
      />
    </View>
  ) : label ? (
    <View style={styles.labelContainer}>
      <Text dataSet={{ media: ids.label }} style={[styles.label, labelStyles]}>
        {label}
      </Text>
    </View>
  ) : (
    <></>
  );

  useEffect(() => {
    const checkBoxValidate = async () => {
      setIsError(false);
      setErrorMessage('');
      if (required && toggleCheckBox) {
        return true;
      } else if (required && !toggleCheckBox) {
        setErrorMessage('This field is required');
        setIsError(true);
        return false;
      }
      return true;
    };

    if (fieldRef) {
      fieldRef.current = {
        validate: checkBoxValidate,
        value: toggleCheckBox,
      };
    }
  }, [fieldRef, toggleCheckBox, required]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ' ') {
      event.preventDefault();
      handlePress();
    }
  };

  const handlePress = () => {
    setHasChanged(true);
    if (onChangeField) {
      onChangeField(!toggleCheckBox);
    }
    setToggleCheckBox(!toggleCheckBox);
  };

  return (
    <>
      <Container onKeyDown={handleKeyDown} containerStyles={styles.mainContainer}>
        <TouchableOpacity disabled={disabled} onPress={handlePress} style={[styles.checkboxContainer, fieldStyles]}>
          <CheckboxFieldWrapper disabled={disabled} toggleCheckBox={toggleCheckBox ?? false} isError={isError} {...props} />
          {labelContainer}
        </TouchableOpacity>
        {isError && errorMessage !== '' && (
          <View style={styles.errorContainer}>
            <Text style={styles.errorText}>{errorMessage}</Text>
          </View>
        )}
      </Container>
    </>
  );
};
