import React, { useCallback, useRef } from 'react';
import { TouchableOpacity, TouchableOpacityProps, View, ViewStyle, ActivityIndicator } from 'react-native';

import { ITheme, useTheme } from '@rugby-au/theme';
import { Icons } from '@rugby-au/icons';
import { Text } from '@rugby-au/commons';

import { getButtonStyles, Size, Weight } from './styles';
import { IconTypes } from '../../icons/src';
import { useAppConfig } from '@rugby-au/app-config';
import { useRouter } from 'next/router';

export interface ButtonProps extends Omit<TouchableOpacityProps, 'onPress'> {
  /** Button appearance */
  appearance?: ITheme;
  /** Button disabled state */
  disabled?: boolean;
  /** Button full width */
  fullWidth?: boolean;
  /** href link path to navigate to */
  href?: string;
  /** Button onPress function */
  onPress?: (e: any) => void;
  /** Button size */
  size?: Size;
  /** Button Title which is displayed in the center of the button */
  title?: string;
  /** transparent background */
  transparentBackground?: boolean;
  /** Button Weight */
  weight?: Weight;
  /** Icon */
  /** Todo Add Icon module with list of icons available */
  icon?: IconTypes;
  /** Position of the icon */
  iconAlignment?: 'left' | 'right';
  children?: React.ReactNode;
  /** border radius */
  borderRadius?: number;
  /** Displays a spinner */
  isLoading?: boolean;
  /** Additional styles for TouchableOpacity container */
  style?: ViewStyle;
  /** Additional params to add to the href link */
  addFromParams?: string[];
}

export const Button = ({
  title,
  onPress,
  disabled = false,
  appearance = 'primary',
  href,
  size = 'medium',
  weight = 'bold',
  fullWidth = false,
  transparentBackground = false,
  icon,
  iconAlignment = 'left',
  children,
  borderRadius = 20,
  isLoading = false,
  style,
  addFromParams,
}: ButtonProps) => {
  const { colorPairs, spacing, sizes } = useTheme();
  const { setNavigation } = useAppConfig();
  const styles = getButtonStyles({
    colors: colorPairs[appearance] ?? colorPairs.primary,
    disabled,
    size: sizes[size] ?? sizes.large,
    weight,
    spacing,
    fullWidth,
    transparentBackground,
    borderRadius,
    isLoading,
  });
  const params = useRouter();

  const hrefParams = useRef<string>('');
  if (addFromParams) {
    for (const param of addFromParams) {
      const paramValue = params.query[param];
      if (paramValue && !hrefParams.current.includes(param as string)) {
        hrefParams.current += `&${param}=${encodeURIComponent(paramValue as string)}`;
      }
    }
    if (hrefParams.current?.startsWith('&')) {
      hrefParams.current = hrefParams.current?.substring(1);
    }
  }

  const handleOnPress = useCallback(() => {
    if (href) {
      hrefParams.current = href + (href?.includes('?') ? '&' : '?') + hrefParams.current;
      setNavigation && setNavigation(hrefParams.current);
    } else if (onPress) {
      onPress(hrefParams.current);
    }
  }, [href, onPress, setNavigation]);
  return (
    <TouchableOpacity style={{ ...styles.container, ...style }} disabled={disabled} onPress={handleOnPress}>
      {icon && iconAlignment === 'left' && (
        <IconContainer icon={icon} containerStyles={{ marginRight: spacing.medium, width: spacing.medium }} color={colorPairs[appearance].foreground.mid} />
      )}

      {isLoading && (
        <View style={{ position: 'absolute' }}>
          <ActivityIndicator size={size === 'medium' ? 'small' : size} color={colorPairs[appearance]?.foreground.mid} />
        </View>
      )}

      <Text style={styles.text}>{title}</Text>
      {icon && iconAlignment === 'right' && (
        <IconContainer icon={icon} containerStyles={{ marginLeft: spacing.medium, width: spacing.medium }} color={colorPairs[appearance].foreground.mid} />
      )}
      {children}
    </TouchableOpacity>
  );
};

const IconContainer = ({ icon, containerStyles, color }: { icon: IconTypes; containerStyles: ViewStyle; color: string }) => {
  return (
    <View style={containerStyles}>
      <Icons type={icon} color={color} />
    </View>
  );
};
