import React from 'react';

import { Image } from '@rugby-au/image';
import { useTheme } from '@rugby-au/theme';
import { Container } from '@rugby-au/container';
import { ImageStyle, StyleProp, View } from 'react-native';

import { WebHeaderProps } from './WebHeader';
import { getDesktopHeaderStyles } from './styles';
import { NavItem } from './NavItem';

export const DesktopWebHeader = ({ appearance = 'primary', ...props }: WebHeaderProps) => {
  const { spacing, colorPairs } = useTheme();

  const colors = colorPairs[appearance];

  const subNavAppearance =
    props?.primaryNav?.navItems?.[0]?.subNav?.appearance ?? props?.primaryNav?.navItems?.find(item => item?.subNav?.appearance)?.subNav?.appearance ?? 'primary';

  const styles = getDesktopHeaderStyles({ spacing, colors });
  return (
    <View style={styles.primaryContainer}>
      <Container>
        <View style={styles.container}>
          <View style={styles.crestContainer}>
            {props.siteLogo && (
              <a href={props.siteLogo.crestNavPath ?? 'https://wallabies.rugby'} style={{ height: '100%' }}>
                <Image url={props.siteLogo.url} alt={props.siteLogo.alt} style={styles.crestStyles as StyleProp<ImageStyle>} />
              </a>
            )}
          </View>
          <View style={styles.crestContainer} />
          <View style={styles.navItemsContainer}>
            {props.primaryNav?.navItems
              .concat(props.quickAccessNav?.navItems ?? [])
              .filter(item => item.isDesktop === undefined || item.isDesktop === true)
              .map((item, i) => {
                return (
                  <NavItem
                    appearance={appearance}
                    subNavAppearance={subNavAppearance}
                    key={i}
                    navItem={item}
                    index={i}
                    isMobile={item.isMobile ?? false}
                    isCart={item.isCart ?? false}
                    isCountDown={item.isCountDown ?? false}
                    onClick={item.onClick}
                  />
                );
              })}
          </View>
        </View>
      </Container>
    </View>
  );
};
