import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useTheme } from '@rugby-au/theme';
import { IconTypes, Icons } from '@rugby-au/icons';
import { Text } from '@rugby-au/commons';

import { getRadioButtonFieldStyles } from './styles';
import { FieldRefProps } from './types';

export interface RadioButtonFieldProps {
  label?: string;
  radioLabel: string;
  defaultChecked?: boolean;
  value: boolean;
  onChangeField: (value: boolean) => void;
  disabled?: boolean;
  align?: 'left' | 'right';
  radioLabelIcons?: IconTypes[];
}
export const RadioButtonField = ({
  label,
  radioLabel,
  value,
  onChangeField,
  defaultChecked = false,
  disabled = false,
  align = 'left',
  radioLabelIcons = [],
  ...props
}: RadioButtonFieldProps) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const { sizes, spacing, colors, typography } = useTheme();

  const iconType = isChecked ? 'circleRadioSelect' : 'circleRadioDeselect';

  useEffect(() => {
    if (value !== undefined && value !== isChecked) {
      setIsChecked(value);
    }
  }, [isChecked, value]);

  const { styles } = getRadioButtonFieldStyles({ colors, spacing, align, typography });
  return (
    <>
      {label && <Text style={styles.label}>{label}</Text>}
      <TouchableOpacity
        disabled={disabled}
        style={styles.radioContainer}
        onPress={() => {
          setIsChecked(!isChecked);
          onChangeField && onChangeField(!isChecked);
        }}
        {...props}>
        {radioLabel && radioLabelIcons.length > 0 ? (
          <View style={styles.radioLabelContainer}>
            <Text style={styles.radioLabel}>{radioLabel}</Text>
            <View style={styles.radioLabelIconsContainer}>
              {radioLabelIcons.map((icon, index) => (
                <View key={index + icon} style={{ paddingRight: spacing.small }}>
                  <Icons type={icon} height={sizes.xsmall.boxSize} width={sizes.small.boxSize} color={isChecked ? colors.primary : colors.neutral} />
                </View>
              ))}
            </View>
          </View>
        ) : radioLabel ? (
          <Text style={styles.radioLabel}>{radioLabel}</Text>
        ) : null}
        <View>
          <Icons type={iconType} height={sizes.xsmall.boxSize} width={sizes.xsmall.boxSize} color={isChecked ? colors.primary : colors.neutral} />
        </View>
      </TouchableOpacity>
    </>
  );
};

export interface RadioButtonGroupFieldProps {
  /** List of radio buttons */
  radioButtonGroup?: { key: string; radioLabel: string }[];
  /** Field Label for the radio button group */
  label?: string;
  /** Default checked radio button */
  defaultValue?: string;
  /** Required value for validation */
  required?: boolean;
  /** Disabled */
  disabled?: boolean;
  /** Radio Button alignment */
  align?: 'left' | 'right';
  /** Field Ref */
  fieldRef?: FieldRefProps;
}

export const RadioButtonGroupField = ({ radioButtonGroup, defaultValue, label, align = 'left', required = false, fieldRef }: RadioButtonGroupFieldProps) => {
  const [selected, setSelected] = useState(defaultValue ?? '');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { spacing, colors, typography } = useTheme();

  const { styles } = getRadioButtonFieldStyles({ colors, spacing, typography });

  useEffect(() => {
    const checkBoxValidate = async () => {
      setIsError(false);
      setErrorMessage('');
      if (required && selected !== '') {
        return true;
      } else if (required && (selected === '' || !selected)) {
        setErrorMessage('This field is required');
        setIsError(true);
        return false;
      }
      return true;
    };

    if (fieldRef) {
      fieldRef.current = {
        validate: checkBoxValidate,
        value: selected,
      };
    }
  }, [fieldRef, selected, required]);

  return (
    <>
      <View>
        {label && (
          <View>
            <Text style={styles.label}>{label}</Text>
          </View>
        )}

        <View style={styles.groupContainer}>
          {radioButtonGroup?.map(radioButton => (
            <RadioButtonField
              key={radioButton.key}
              radioLabel={radioButton.radioLabel}
              value={selected === radioButton.key}
              onChangeField={value => {
                if (value) {
                  setSelected(radioButton.key);
                }
              }}
              align={align}
            />
          ))}
        </View>
      </View>
      {isError && errorMessage !== '' && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{errorMessage}</Text>
        </View>
      )}
    </>
  );
};
