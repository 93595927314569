import React from 'react';
import { View } from 'react-native';

import { ITheme, useTheme } from '@rugby-au/theme';
import { Text } from '@rugby-au/commons';

import { getBillSubtotalStyles } from './styles';

export interface BillSubtotalProps {
  /** Propduct appearance */
  appearance?: ITheme;
  /** Container Styles */
  containerStyles?: any;
  /** Subtotal price of Bills */
  subtotal: number;
  /** discount */
  totalDiscount?: number;
  /** Donation */
  donation?: number;
  processingFee?: number;
  /** Children */
  children?: React.ReactNode;
}

export const BillSubtotal = ({ appearance = 'primary', containerStyles, subtotal, totalDiscount, processingFee, donation, children, ...props }: BillSubtotalProps) => {
  const { colorPairs, spacing, headings, typography } = useTheme();
  const styles = getBillSubtotalStyles({
    spacing,
    headings,
    typography,
    colors: colorPairs[appearance],
  });
  return (
    <View
      //onPress={}
      {...props}
      style={{ ...styles.container, ...containerStyles }}>
      <View style={{ ...styles.billSubtotalContainer }}>
        <Text style={{ ...styles.headerText }}>Subtotal</Text>
        {subtotal && <Text style={{ ...styles.itemText }}>{`$${subtotal}`}</Text>}
      </View>
      {totalDiscount && totalDiscount > 0 ? (
        <View style={{ ...styles.billSubtotalContainer }}>
          <Text style={{ ...styles.headerText }}>Total Discount</Text>
          <Text style={{ ...styles.itemText }}>{`$${totalDiscount}`}</Text>
        </View>
      ) : null}
      {donation && donation > 0 ? (
        <View style={{ ...styles.billSubtotalContainer }}>
          <Text style={{ ...styles.headerText }}>Donation</Text>
          <Text style={{ ...styles.itemText }}>{`$${donation}`}</Text>
        </View>
      ) : null}
      <View style={{ ...styles.billSubtotalContainer }}>
        <Text style={{ ...styles.headerText }}>Processing Fee</Text>
        {processingFee && <Text style={{ ...styles.itemText }}>{`$${processingFee}`}</Text>}
      </View>
      {children}
    </View>
  );
};
