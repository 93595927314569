import React, { ReactNode, createContext, useContext, useMemo } from 'react';
import merge from 'deepmerge';

import { getTheme } from './theme';

const theme = getTheme({});

export type ThemeType = typeof theme;

const ThemeContext = createContext<{
  theme: ThemeType;
}>({
  theme,
});

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends object ? RecursivePartial<T[P]> : T[P];
};

export const ThemeProvider = ({ theme: providedTheme, children }: { theme?: RecursivePartial<ThemeType>; children: ReactNode }) => {
  const value = useMemo(() => ({ theme: merge(theme, providedTheme ?? {}) }), [providedTheme]);
  return (
    <ThemeContext.Provider
      // @ts-ignore
      value={value}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const { theme: themeContext } = useContext(ThemeContext);

  return { ...themeContext };
};
