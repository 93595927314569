import React, { useState } from 'react';

import { Image } from '@rugby-au/image';
import { ITheme, useTheme } from '@rugby-au/theme';
import { Container } from '@rugby-au/container';
import { View, Pressable, Modal, ScrollView } from 'react-native';
import { Icons, IconTypes } from '@rugby-au/icons';
import { Text } from '@rugby-au/commons';

import { NavItemProps, WebHeaderProps } from './WebHeader';
import { getMobileHeaderStyles, getNavBarStyles } from './styles';
import { NavItem } from './NavItem';

export const MobileWebHeader = ({ appearance = 'primary', ...props }: WebHeaderProps) => {
  const { spacing, colorPairs, typography } = useTheme();
  const [isNavOpen, setIsNavOpen] = useState(false);

  const colors = colorPairs[appearance];

  const subNavAppearance =
    props?.primaryNav?.navItems?.[0]?.subNav?.appearance ?? props?.primaryNav?.navItems?.find(item => item?.subNav?.appearance)?.subNav?.appearance ?? 'primary';

  const styles = getMobileHeaderStyles({ spacing, colors });

  const mobileHeader = (
    <View style={styles.primaryContainer}>
      <Container>
        <View style={styles.container}>
          <View style={styles.crestContainer}>
            {props.siteLogo && (
              <a href={'https://wallabies.rugby'} style={{ height: '100%' }}>
                <Image url={props.siteLogo.url} alt={props.siteLogo.alt} style={styles.crestStyles} />
              </a>
            )}
          </View>
          <View style={styles.navItemsContainer}>
            {props?.quickAccessNav?.navItems
              .filter(item => {
                return item.isMobile === undefined || item.isMobile === true;
              })
              .map((item, i) => {
                return (
                  <NavItem
                    isMobile={item.isMobile ?? false}
                    appearance={appearance}
                    subNavAppearance={subNavAppearance}
                    key={i}
                    navItem={item}
                    index={i}
                    isCart={item.isCart ?? false}
                    isCountDown={item.isCountDown ?? false}
                    onClick={item.onClick}
                  />
                );
              })}
          </View>
          {props.primaryNav?.navItems && props.primaryNav?.navItems?.length ? (
            <Pressable
              style={styles.menuIconContainer}
              onPress={() => {
                setIsNavOpen(!isNavOpen);
              }}>
              <Text style={{ color: colors.foreground.mid, paddingHorizontal: spacing.small }}>
                <Icons
                  type={isNavOpen ? ('close' as IconTypes) : ('menu' as IconTypes)}
                  color={colors.foreground.mid}
                  height={typography.fontSize.xlarge}
                  width={typography.fontSize.xlarge}
                />
              </Text>
            </Pressable>
          ) : null}
        </View>
      </Container>
    </View>
  );
  return (
    <>
      {mobileHeader}
      <Modal animationType={'fade'} visible={isNavOpen} onRequestClose={() => setIsNavOpen(false)}>
        <View>{mobileHeader}</View>
        <ScrollView style={{ paddingVertical: spacing['3xlarge'], backgroundColor: colors.background.light }}>
          {props.primaryNav?.navItems.map((item, i) => {
            return <NavBarItem key={`nav-bar-${i}`} appearance={appearance} subNavAppearance={subNavAppearance} navItem={item} />;
          })}
        </ScrollView>
      </Modal>
    </>
  );
};

const NavBarItem = ({ appearance = 'light', subNavAppearance = 'primary', navItem }: { appearance: ITheme; subNavAppearance: ITheme; navItem: NavItemProps }) => {
  const [isSubNavOpen, setIsSubNavOpen] = useState(false);
  const { spacing, colorPairs, typography } = useTheme();

  const colors = colorPairs[appearance];
  const subNavColors = colorPairs[subNavAppearance];

  const styles = getNavBarStyles({ spacing, colors, subNavColors, typography, isSubNavOpen });
  return (
    <Pressable onPress={() => setIsSubNavOpen(!isSubNavOpen)}>
      <a href={navItem.href} style={styles.navBarItem}>
        <Text style={styles.navItemTitle}>{navItem.title}</Text>
      </a>
      {isSubNavOpen && (
        <View style={{ backgroundColor: colors.background.dark }}>
          {navItem.subNav?.navItems?.map((subItem, index) => (
            <a key={index + subItem.title} href={subItem.href} style={styles.subNavContainer}>
              <Text style={styles.subNavTitle}>{subItem.title}</Text>
            </a>
          ))}
        </View>
      )}
    </Pressable>
  );
};
