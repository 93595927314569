import React, { useEffect, useRef, MutableRefObject, useCallback } from 'react';
import { TextStyle, View } from 'react-native';

import { useTheme } from '@rugby-au/theme';
import { Text } from '@rugby-au/commons';

import { TextInputFieldWrapper } from '../TextInput';
import { getTextInputStyles } from '../styles';
import { useAddressField } from './useAddressField';
import { ManualAddressField } from './ManualAddressField';

export interface AddressProps {
  formattedAddress?: string;
  addressline1: string;
  addressline2: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
}

export const AddressField = ({
  onChangeField,
  fieldRef,
  disabled = false,
  labelInfoText,
  ...props
}: {
  onChangeField?: (value: AddressProps) => void;
  value?: AddressProps;
  fieldRef: MutableRefObject<any>;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  labelInfoText?: string;
}) => {
  const defaultValue = props?.value ?? undefined;
  // Remove value prop as google maps API controls it
  props && (props.value || props.value === '') && delete props.value;
  const inputRef = useRef<HTMLInputElement>();
  //Temp reference to hold the value to fix when user unchecks 'This is for my self' checkbox,
  //and the prop value is empty and but field displays a value
  const valueRef = useRef<any>();

  const [error, setError] = React.useState<string | undefined>(undefined);
  const { colors, radius, spacing, typography } = useTheme();

  const { selectedAddress, setSelectedAddress, setIsManualAddress } = useAddressField({ inputRef, defaultValue });

  const { ids, styles } = getTextInputStyles({ isError: !!error, colors, radius, spacing, typography, disabled: false });

  useEffect(() => {
    selectedAddress && onChangeField && onChangeField(selectedAddress);
  }, [selectedAddress, onChangeField]);

  useEffect(() => {
    if (defaultValue && selectedAddress?.addressline1 !== defaultValue?.addressline1) {
      setSelectedAddress(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const getValue = useCallback(() => {
    return selectedAddress && selectedAddress?.addressline1 ? selectedAddress : defaultValue ? defaultValue : valueRef.current ? valueRef.current : undefined;
  }, [defaultValue, selectedAddress]);

  const validateField = useCallback(async () => {
    let isValid = true;
    if (props.required && !disabled && selectedAddress) {
      if (!selectedAddress?.addressline1 || !selectedAddress?.city || !selectedAddress?.postcode || !selectedAddress?.country) {
        isValid = false;
      }
    } else if (props.required && !disabled && !defaultValue?.addressline1) {
      isValid = false;
    }

    // removed auto populating from the address text as this resulted in incorrect address being added to the form
    // check if ref values is available
    // if (inputRef.current?.value && !isManualAddress) {
    //   const addressObj = await getAddressfromAddressStr(inputRef?.current.value);
    //   if (props.required && addressObj && (addressObj.addressline1 || addressObj.city || addressObj.state || addressObj.postcode || addressObj.country)) {
    //     valueRef.current = addressObj;
    //     isValid = true;
    //   } else if (props.required && inputRef.current?.value === '' && !defaultValue?.addressline1 && !selectedAddress?.addressline1) {
    //     isValid = false;
    //   }
    // }
    if (!isValid) {
      console.error('Invalid address error', 'input', inputRef.current?.value, 'default', defaultValue, 'selected', selectedAddress);
      setError('Please ensure that you have selected a valid address or enter the address manually.');
    }

    return isValid;
  }, [defaultValue, props.required, disabled, selectedAddress]);

  useEffect(() => {
    if (fieldRef) {
      fieldRef.current = {
        validate: validateField,
        value: getValue,
      };
    }
  }, [fieldRef, getValue, validateField]);

  const defaultValueStr =
    defaultValue && defaultValue?.formattedAddress
      ? defaultValue?.formattedAddress
      : `${defaultValue?.addressline1 ?? ''}${defaultValue?.addressline2 ? `${defaultValue?.addressline2}` : ''} ${defaultValue?.city ?? ''} ${defaultValue?.state ?? ''} ${
          defaultValue?.postcode ?? ''
        } ${defaultValue?.country ?? ''}`;
  return (
    <View>
      <View dataSet={{ media: ids.labelContainer }} style={styles.labelContainer}>
        {props.label && (
          <Text style={styles.label as TextStyle}>
            {props.label} {props.required && '*'}
          </Text>
        )}
        {labelInfoText && <Text style={styles.labelInfoText as TextStyle}>{labelInfoText}</Text>}
      </View>
      <TextInputFieldWrapper
        ref={inputRef}
        fieldContainerStyles={styles.fieldContainer}
        {...(defaultValue
          ? {
              defaultValue: defaultValueStr,
              placeHolder: defaultValueStr,
            }
          : {})}
        disabled={disabled}
        {...props}
        data-media={ids.input}
      />
      {!disabled && (
        <ManualAddressField
          onAddressChange={value => {
            setSelectedAddress(value);
            setIsManualAddress(true);
            if (inputRef && inputRef.current) {
              inputRef.current.value = value.formattedAddress ?? '';
            }
          }}
        />
      )}
      {error && <Text style={styles.error}>{error}</Text>}
    </View>
  );
};
