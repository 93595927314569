import React, { useMemo } from 'react';

import { ButtonProps } from '@rugby-au/button';
import { RxImageProps } from '@rugby-au/image';
import { ITheme } from '@rugby-au/theme';
import { DesktopWebHeader } from './DesktopWebHeader';
import { MobileWebHeader } from './MobileWebHeader';
import { getHeadingStyles } from './styles';
import { useAppConfig } from '@rugby-au/app-config';
// import { logout } from '@rugby-au/business';

export interface WebHeaderProps {
  globalNav?: NavProps;
  primaryNav?: NavProps;
  quickAccessNav?: NavProps;
  siteLogo?: RxImageProps & { crestNavPath?: string };
  isFeaturedHeader?: boolean;
  isSearchPage?: boolean;
  appearance?: ITheme;
  deviceNavAppearance?: ITheme;
}

export interface NavProps {
  navItems: NavItemProps[];
  appearance?: ITheme;
  displayCart?: boolean;
  displayUserOptions?: boolean;
}

export interface NavItemProps {
  title: string;
  subNav?: NavProps;
  icon?: string;
  /**Display Button */
  button?: ButtonProps & { text: string };
  active?: boolean;
  href?: string;
  /**Display in mobile */
  isMobile?: boolean;
  isDesktop?: boolean;
  isCart?: boolean;
  isCountDown?: boolean;
  onClick?: () => void;
  stopTranform?: boolean;
}

export interface CartProps {
  displayCart?: boolean;
  cartCount?: number;
}

const profileNavItem = ({ isLogged, isPrimaryNav }: { isLogged: boolean; isPrimaryNav?: boolean }): NavItemProps => ({
  title: 'Profile',
  icon: 'profile',
  ...(!isPrimaryNav ? { href: isLogged ? '/profile' : '/login' } : {}),
  isMobile: isPrimaryNav ? true : false,
  isDesktop: isPrimaryNav ? false : true,
  // onClick: async () => {
  //   await logout();
  //   alert('here');
  //   setIsLogged(false);
  //   window.location.href = '/';
  // },
  subNav: {
    navItems: [
      ...(isLogged
        ? [
            {
              title: 'My Profile',
              href: '/profile?subPage=myprofile',
            },
            {
              title: 'My Memberships',
              href: '/profile?subPage=mymemberships',
            },
            {
              title: 'Logout',
              href: '/logout',
            },
          ]
        : [
            {
              title: 'Login',
              href: `${process.env.NEXT_PUBLIC_AUTH_URL}login?clientId=memberships&codeChallenge=&redirectPath=/`,
            },
            // {
            //   title: 'Sign Up',
            //   href: '/register',
            // },
          ]),
    ],
  },
});
export const WebHeader = ({ appearance = 'primary', ...props }: WebHeaderProps) => {
  const { isLogged, cart } = useAppConfig();
  const styles = getHeadingStyles();

  const _quickAccessNav = useMemo(
    () => ({
      ...props.quickAccessNav,
      navItems: [
        ...(props.quickAccessNav?.navItems ?? []),
        ...(props.quickAccessNav?.displayCart
          ? [
              {
                title: 'Cart',
                icon: 'cart',
                href: '/checkout?initLayout=checkCart',
                isCart: true,
                isMobile: true,
                isDesktop: true,
              },
            ]
          : []),
        ...(props.quickAccessNav?.displayUserOptions ? [profileNavItem({ isLogged, isPrimaryNav: false })] : []),
        ...(cart?.meta?.cartExpiry
          ? [
              {
                title: 'Cart Expiry',
                isCountDown: true,
                href: '/checkout?initLayout=checkCart',
                isMobile: true,
                isDesktop: true,
              },
            ]
          : []),
      ],
    }),
    [props.quickAccessNav, isLogged, cart?.meta?.cartExpiry],
  );

  const _primaryNav = useMemo(
    () => ({
      ...props.primaryNav,
      navItems: [...(props.quickAccessNav?.displayUserOptions ? [profileNavItem({ isLogged, isPrimaryNav: true })] : []), ...(props.primaryNav?.navItems ?? [])],
    }),
    [props.primaryNav, props.quickAccessNav?.displayUserOptions, isLogged],
  );
  return (
    <header style={styles.header}>
      <div className="display-mobile">
        <MobileWebHeader appearance={appearance} {...props} quickAccessNav={_quickAccessNav} primaryNav={_primaryNav} />
      </div>
      <div className="display-desktop">
        <DesktopWebHeader appearance={appearance} {...props} quickAccessNav={_quickAccessNav} primaryNav={_primaryNav} />
      </div>
    </header>
  );
};
