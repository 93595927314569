import { ISiteColors, IRadius, ISpacing, ITypography } from '@rugby-au/theme';
import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const getPickerInputStyles = ({
  isError,
  //isValid,
  disabled,
  colors,
  radius,
  spacing,
  typography,
}: {
  isError: boolean;
  //isValid: boolean;
  disabled: boolean;
  colors: ISiteColors;
  radius: IRadius;
  spacing: ISpacing;
  typography: ITypography;
}) => {
  return StyleSheet.create({
    fieldContainer: {
      boxSizing: 'border-box',
      //flex: 1,
      backgroundColor: disabled ? colors.neutralExtraLight : colors.primaryText,
      borderWidth: 1,
      borderColor: isError ? colors.danger : colors.neutralLight,
      borderRadius: radius.medium,
      // alignSelf: 'center',
      // flexGrow: 0,
      // padding: spacing['2xsmall'],
      // paddingLeft: spacing.medium,
      padding: spacing['2xsmall'],
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    modal: {
      marginTop: 'auto',
      backgroundColor: colors.primaryText,
    },
    label: { color: colors.neutralDark, marginBottom: spacing.small, fontFamily: typography.fontFamily.semiBold },
    item: {
      color: colors.neutralDark,
      fontFamily: typography.fontFamily.regular,
      paddingRight: spacing.small,
      alignSelf: 'center',
    },
    field: { width: '100%' },
    error: { color: colors.danger },
  });
};

export const getDatePickerFieldStyles = ({
  isError,
  isValid,
  disabled,
  colors,
  radius,
  spacing,
  // clearIcon,
  typography,
}: {
  isError: boolean;
  isValid: boolean;
  disabled: boolean;
  colors: ISiteColors;
  radius: IRadius;
  spacing: ISpacing;
  // clearIcon?: boolean;
  typography: ITypography;
}) => {
  let iconWidth = 10;
  // if (clearIcon) {
  //   iconWidth += 10;
  // }
  if (isValid || isError) {
    iconWidth += 10;
  }
  const container: {
    background?: string;
    backgroundColor?: string;
  } = {
    background: disabled ? colors.neutralExtraLight : colors.primaryText,
    backgroundColor: disabled ? colors.neutralExtraLight : colors.primaryText,
  };
  if (Platform.OS === 'web') {
    delete container.background;
  }
  return StyleSheet.create({
    wrapperContainer: {
      borderWidth: 1,
      borderColor: isError ? colors.danger : colors.neutralLight,
      borderRadius: radius.medium,
    },
    fieldContainer: {
      ...container,
      flex: 1,
      // background: disabled ? colors.neutral : colors.primaryText,
      boxSizing: 'border-box',
      'border-style': 'solid',
      borderWidth: 1,
      borderColor: isError ? colors.danger : colors.neutralLight,
      borderRadius: radius.medium,
      alignSelf: 'stretch',
      flexGrow: 0,
      padding: spacing.medium,
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontFamily: typography.fontFamily.regular,
      width: '100%',
      '@media (min-width: 768px)': { fontSize: typography.fontSize.medium },
      fontSize: typography.fontSize.medium,
    },
    label: { color: colors.neutralDark, marginBottom: spacing.small, fontFamily: typography.fontFamily.semiBold },
    field: { width: `${100 - iconWidth}%` },
    error: { color: colors.danger },
  });
};
