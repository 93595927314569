export const obfuscateEmail = (email: string): string => {
  try {
    if (email && email.length) {
      const emailParts = email.split('@');
      if (emailParts.length === 2) {
        return obfuscateString(emailParts[0]) + '@' + obfuscateString(emailParts[1]);
      }
      return email;
    }
    return '';
  } catch (error) {
    console.error('obfuscateString error:', error);
    return email;
  }
};

export const obfuscateString = (str: string): string => {
  try {
    if (str && str.length) {
      const strLength = str.length;
      let charactersDisplay = 3;
      if (strLength < 3) {
        charactersDisplay = strLength;
      }
      return str.slice(0, charactersDisplay) + '*'.repeat(5);
    }
    return '';
  } catch (error) {
    console.error('obfuscateString error:', error);
    return str;
  }
};
