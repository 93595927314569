import { Button } from '@rugby-au/button';
import { useTheme } from '@rugby-au/theme';
import { Text } from '@rugby-au/commons';

import React, { useState, useEffect, useCallback } from 'react';
import { View } from 'react-native';
import { getQuantityFieldStyles } from '../styles';
import { AdditionalMessageProps, FieldRefProps } from '../types';
import { QuantityInput } from './QuantityInput';

export interface QuantityInputFieldProps {
  /**value */
  value?: number;
  /** Label */
  label?: string;
  /** is disabled */
  disabled?: boolean;
  /** Is Required */
  required?: boolean;
  /** invoke in on change */
  onChangeField?: (value: number) => void;
  /**fieldRef */
  fieldRef?: FieldRefProps;
  max?: number;
  min?: number;
  step?: number;
}

export const QuantityInputField = ({ label, disabled = false, value, required = false, onChangeField, fieldRef, min = 0, step = 1, max }: QuantityInputFieldProps) => {
  // Keeping a seperate state for the value to avoid react rendering delays
  const [inputValue, setInputValue] = useState(value ?? 0);

  const [error, setError] = useState('');
  const [additionalMessage, setAdditionalMessage] = useState<AdditionalMessageProps>();
  const [hasChanged, setHasChanged] = useState(false);
  const { colors, radius, spacing, typography } = useTheme();
  // const textFieldRef = useRef<FieldRefMethodProps>();

  // On init assign default input value to state
  useEffect(() => {
    if (value && inputValue !== value && !hasChanged) {
      setInputValue(value);
    }
  }, [value, inputValue, hasChanged]);

  // useEffect(() => {
  //   if (textFieldRef.current && textFieldRef.current.value !== inputValue && !!textFieldRef.current.setValue) {
  //     textFieldRef.current.setValue(`${inputValue}`);
  //   }
  // }, [hasChanged, inputValue, onChangeField]);

  const validate = useCallback(async () => {
    return true;
  }, []);

  const handleOnChange = useCallback(
    (_value: number) => {
      if (onChangeField) {
        onChangeField(_value);
      }
      if (!hasChanged) {
        setHasChanged(true);
      }
    },
    [hasChanged, onChangeField],
  );

  useEffect(() => {
    if (fieldRef) {
      fieldRef.current = {
        validate: validate,
        value: inputValue,
        setError: setError,
        setAdditionalMessage: setAdditionalMessage,
      };
    }
  }, [fieldRef, inputValue, validate]);

  const { styles } = getQuantityFieldStyles({ isError: !!error, disabled, colors, radius, spacing, typography });

  return (
    <View>
      <View>
        {label && (
          <Text style={styles.label}>
            {label} {required && '*'}
          </Text>
        )}
      </View>
      <QuantityInput
        value={inputValue}
        disabled={disabled}
        max={max}
        min={min}
        onChangeField={_value => {
          setInputValue(_value);
          handleOnChange(_value);
        }}
        step={step}
      />

      {error && <Text style={styles.error}>{error}</Text>}
      {additionalMessage && (
        <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Text style={styles.error}>{additionalMessage.firstText}</Text>
          {additionalMessage.onPress && (
            <View>
              <Button title={additionalMessage.linkText} weight={'none'} onPress={additionalMessage.onPress} style={{ padding: 0, margin: 0, flex: 0 }} />
            </View>
          )}
          {additionalMessage.secondText && <Text style={styles.error}>{additionalMessage.secondText}</Text>}
        </View>
      )}
    </View>
  );
};
