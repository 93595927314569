import { Text } from '@rugby-au/commons';
import { useTheme } from '@rugby-au/theme';
import React from 'react';
import { View } from 'react-native';

import { getLabelFieldStyles } from './styles';

export interface LabelFieldProps {
  /** Label */
  label?: string;
  /** Label Styles */
  labelStyles?: any;
  /** Field Container styles */
  fieldContainerStyle?: any;
}

export const LabelField = ({ label, labelStyles, fieldContainerStyle = {}, ...props }: LabelFieldProps) => {
  const { colors, typography } = useTheme();
  const { styles } = getLabelFieldStyles({ colors, typography });

  return (
    <>
      <View style={fieldContainerStyle}>{label && <Text style={{ ...styles.label, ...labelStyles, ...props }}>{label}</Text>}</View>
    </>
  );
};
