import { useState, useEffect, useRef, MutableRefObject, useCallback } from 'react';

import { useAppConfig } from '@rugby-au/app-config';
import { AddressProps } from './AddressField';

declare global {
  interface Window {
    google: any;
  }
}
export const useAddressField = ({ inputRef }: { inputRef: MutableRefObject<HTMLInputElement | undefined>; defaultValue?: AddressProps }) => {
  const [selectedAddress, setSelectedAddress] = useState<AddressProps | null>(null);
  const [isManualAddress, setIsManualAddress] = useState<boolean>(false);

  const mapRef = useRef();

  const { nationalId } = useAppConfig();

  const getAddressObjFromPlace = useCallback((place: any) => {
    const addressComponents: { [key: string]: string } = {};

    place?.address_components?.forEach((component: any) => {
      component.types.forEach((type: any) => {
        if (type === 'administrative_area_level_1') {
          addressComponents[type] = component.short_name;
        } else {
          addressComponents[type] = component.long_name;
        }
      });
    });

    let placeAddressLine1 =
      (place.name ? `${place.name}` : '') + (place.name && addressComponents.route ? ', ' : '') + (addressComponents.route ? addressComponents.route : '');
    let formattedAddress = place.formatted_address;

    const inputValue = inputRef.current?.value;
    const inputValueAddressLine1 = inputValue?.split(',')[0] || '';

    if (placeAddressLine1 !== inputValueAddressLine1) {
      const formattedAddressArry = formattedAddress?.split(',') || [];
      formattedAddressArry[0] = inputValueAddressLine1;
      formattedAddress = formattedAddressArry.join(',');
      placeAddressLine1 = inputValueAddressLine1;
    }

    return {
      formattedAddress: formattedAddress,
      addressline1: placeAddressLine1,
      addressline2: '',
      city: addressComponents.locality,
      state: addressComponents.administrative_area_level_1,
      postcode: addressComponents.postal_code,
      country: addressComponents.country,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (typeof window.google === 'undefined') {
      return console.error('Google has not been found. Make sure your provide apiKey prop.');
    }

    if (!window.google.maps.places) {
      return console.error('Google maps places API must be loaded.');
    }

    mapRef.current = new window.google.maps.places.Autocomplete(inputRef.current, { componentRestrictions: { country: nationalId === 'RAU' ? 'au' : 'us' } }) as any;

    if (mapRef.current) {
      (mapRef.current as any).addListener('place_changed', () => {
        const place = (mapRef.current as any).getPlace();
        const formattedAddress = getAddressObjFromPlace(place);

        setSelectedAddress(formattedAddress);
        setIsManualAddress(false);
      });
    }
  }, [getAddressObjFromPlace, inputRef, nationalId]);

  const getAddressfromAddressStr = (addressStr: string) => {
    return new Promise<AddressProps | null>((res, rej) => {
      try {
        let autocompleteService = new window.google.maps.places.AutocompleteService();
        let request = { input: addressStr };
        autocompleteService.getPlacePredictions(request, (predictionsArr: any, placesServiceStatus: any) => {
          if (placesServiceStatus === 'OK') {
            let placeRequest = { placeId: predictionsArr[0].place_id };
            let placeService = new window.google.maps.places.PlacesService(inputRef.current);
            placeService.getDetails(placeRequest, (placeResult: any, placeServiceStatus: any) => {
              if (placeServiceStatus === 'OK') {
                const formattedAddress = getAddressObjFromPlace(placeResult);

                res(formattedAddress);
              } else {
                res(null);
              }
            });
          } else {
            res(null);
          }
        });
      } catch (error) {
        rej(error);
      }
    });
  };

  return { selectedAddress, setSelectedAddress, getAddressfromAddressStr, isManualAddress, setIsManualAddress };
};
