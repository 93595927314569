import merge from 'deepmerge';

export interface IColorScale {
  light: string;
  mid: string;
  dark: string;
}

export interface IColorGroup {
  foreground: IColorScale;
  background: IColorScale;
  neutral: IColorScale;
  ':up': {
    foreground: IColorScale;
    background: IColorScale;
  };
  ':down': {
    foreground: IColorScale;
    background: IColorScale;
  };
  ':disabled': {
    foreground: IColorScale;
    background: IColorScale;
  };
}

export interface ISizeGroup {
  fontSizeDisplay: number;
  fontSizeText: number;
  borderRadius: number;
  boxSize: number;
  gap: number;
  paddingX: number;
  paddingY: number;
}

export interface ISiteColors {
  background: string;
  backgroundLight: string;
  foreground: string;
  primary: string;
  primaryText: string;
  secondary: string;
  secondaryText: string;
  accent: string;
  accentText: string;
  neutral: string;
  neutralExtraLight: string;
  neutralLight: string;
  neutralDark: string;
  neutralExtraDark: string;
  globalNavBackground: string;
  danger: string;
  dangerLight: string;
}

// export type IMediaBreakpoints = {
//   large: string;
//   medium: string;
//   small: string;
//   xlarge: string;
// };

export type ITheme = typeof themeValues[number];
export type IRadius = typeof radius;
export type ISpacing = typeof spacing;
export type ITypography = typeof typography;
export type ISizing = typeof sizing;

// type IBreakPoints = {
//   large: number;
//   medium: number;
//   small: number;
//   xlarge: number;
// };

export type IBreakPoints = typeof breakpoints;

export const themeValues = ['accent', 'dark', 'default', 'hero', 'highlight', 'light', 'neutral-dark', 'primary', 'secondary', 'subtle', 'vibrant', 'vivid'] as const;

export const palette = {
  black: '#000',
  red: '#f56565',
  transparent: 'transparent',
  white: {
    light: '#FFFFFF',
    mid: '#FFFFFF',
    dark: '#F1F5F9',
  },
  neutral: {
    light: '#E3E3E3',
    mid: '#646464',
    dark: '#121221',
  },
  'neutral-dark': {
    light: '#f2f2f2',
    mid: '#121221',
    dark: '#090910',
  },
  'neutral-light': {
    light: '#242442',
    mid: '#eeeeee',
    dark: '#d9d9d9',
  },
  primary: {
    light: '#C5E6FF',
    mid: '#171772',
    dark: '#040339',
  },
  secondary: {
    light: '#de66ff',
    mid: '#C800FF',
    dark: '#780099',
  },
  tertiary: {
    light: '#bfa373',
    mid: '#b18f54',
    dark: '#9d7e48',
  },
};

const radius = {
  none: 0,
  xsmall: 4,
  small: 6,
  medium: 8,
  large: 12,
  full: 9999,
};

const spacing = {
  none: 0,
  px: 1,
  '2xsmall': 2,
  xsmall: 4,
  small: 8,
  medium: 12,
  large: 16,
  xlarge: 20,
  '2xlarge': 24,
  '3xlarge': 32,
  '4xlarge': 40,
  '5xlarge': 48,
  '6xlarge': 64,
  '7xlarge': 80,
  '8xlarge': 96,
  '9xlarge': 128,
};

const typography = {
  fontFamily: {
    bold: 'FuturaMaxiCGBold',
    regular: 'OpenSauceTwo-Regular',
    semiBold: 'OpenSauceTwo-SemiBold',
    sans: 'OpenSauceTwo-Regular',
  },

  fontSize: {
    '4xsmall': 8,
    '3xsmall': 9,
    '2xsmall': 10,
    xsmall: 12,
    small: 13,
    medium: 14,
    large: 16,
    xlarge: 18,
    '2xlarge': 24,
    '3xlarge': 30,
    '4xlarge': 36,
    '5xlarge': 48,
    '6xlarge': 64,
    '7xlarge': 80,
    '8xlarge': 96,
  },

  fontWeight: {
    hairline: '100',
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  leading: {
    tighter: 1,
    tight: 1.2,
    base: 1.4,
    loose: 1.6,
    looser: 1.8,
  },
  tracking: {
    tighter: '-0.02em',
    tight: '-0.01em',
    base: '0em',
    loose: '0.01em',
    looser: '0.02em',
  },
};

const sizing = {
  '2xsmall': 16,
  xsmall: 24,
  small: 32,
  medium: 40,
  large: 48,
  xlarge: 56,
  '2xlarge': 64,
  '3xlarge': 72,
  '4xlarge': 100,
};

const breakpoints = {
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200,
};

// export const mediaQueries: IMediaBreakpoints | {} = Object.keys(breakpoints).reduce<Record<string, string>>((prev, bp) => {
//   prev[bp] = `@media (min-width: ${breakpoints[bp as IBreakPointKeys]}px)`;
//   return prev;
// }, {} as Record<`${IBreakPointKeys}`, string>);

// const global = [
//   {
//     body: {
//       fontFamily: 'FuturaMaxiWXX-Book,"Helvetica Neue",Helvetica,Arial,sans-serif',
//       fontDisplay: 'swap',
//       fontSize: typography.fontSize.medium,
//       lineHeight: 1.6,
//       width: '100%',
//       ' *:focus ': {
//         outline: 'none',
//       },

//       'main p, main ul.bulleted, main ol.bulleted': {
//         marginBottom: spacing.xlarge,
//       },

//       'main ul.bulleted': {
//         listStyle: 'square outside none',
//         marginLeft: spacing.large,
//       },

//       'main ol.bulleted': {
//         listStyle: 'decimal outside none',
//         marginLeft: spacing.large,
//       },
//     },
//     '.grecaptcha-badge': {
//       display: 'none',
//     },
//   },
// ];

export type GetThemeProps = { siteColors?: { [key: string]: IColorScale }; siteTypography?: { [key: string]: any } };
export const getTheme = ({ siteColors, siteTypography }: GetThemeProps) => {
  const colors = merge(palette, siteColors ? siteColors : {});
  const mergedTypography = merge(typography, siteTypography ? siteTypography : {}) as ITypography;
  return {
    breakpoints,
    spacing,
    radius,
    typography: mergedTypography as ITypography,
    palette: { ...palette },
    colors: {
      background: colors.neutral.light,
      backgroundLight: colors.white.light,
      foreground: colors.neutral.dark,
      primary: colors.primary.mid,
      primaryText: colors.white.light,
      secondary: colors.secondary.mid,
      secondaryText: colors.primary.mid,
      tertiary: colors.secondary.mid, //Same as secondary by default.
      tertiaryText: colors.primary.mid, //Same as secondary by default.
      accent: colors.primary.dark,
      accentText: colors.neutral.light,
      neutral: colors.neutral.mid,
      neutralExtraLight: '#eee',
      neutralLight: colors.neutral.light,
      neutralDark: colors.neutral.dark,
      neutralExtraDark: '#333',
      globalNavBackground: colors.primary.dark,
      headerBackground: colors.primary.mid,
      footerBackground: colors.primary.mid,
      danger: '#E11900',
      dangerLight: '#ff8566',
      green: '#05944F',
      tranparent: colors.transparent,
    },
    headings: {
      '1': {
        fontSize: mergedTypography.fontSize['3xlarge'],
        fontWeight: mergedTypography.fontWeight.normal,
        fontFamily: mergedTypography.fontFamily.bold,
        // transform: 'none',
        color: colors.primary.mid,
        marginBottom: spacing['2xlarge'],
      },
      '2': {
        fontSize: mergedTypography.fontSize['2xlarge'],
        fontWeight: mergedTypography.fontWeight.normal,
        fontFamily: mergedTypography.fontFamily.bold,
        // transform: 'none',
        color: colors.primary.mid,
        marginBottom: spacing.xlarge,
      },
      '3': {
        fontSize: mergedTypography.fontSize.xlarge,
        fontWeight: mergedTypography.fontWeight.normal,
        fontFamily: mergedTypography.fontFamily.bold,
        color: colors.primary.mid,
        marginBottom: spacing.small,
      },
      '4': {
        fontSize: mergedTypography.fontSize.large,
        fontFamily: mergedTypography.fontFamily.semiBold,
        color: colors.primary.mid,
        marginBottom: spacing.medium,
      },
      '5': {
        fontSize: mergedTypography.fontSize.medium,
        fontFamily: mergedTypography.fontFamily.semiBold,
        color: colors.primary.mid,
        marginBottom: spacing.small,
      },
      '6': {
        fontSize: mergedTypography.fontSize.small,
        fontFamily: mergedTypography.fontFamily.semiBold,
        color: colors.primary.mid,
        marginBottom: spacing.medium,
      },
    },

    table: {
      headings: {
        small: {
          fontSize: mergedTypography.fontSize.small,
          fontWeight: mergedTypography.fontWeight.normal,
          fontFamily: mergedTypography.fontFamily.bold,
          padding: `${spacing.large} ${spacing.small}`,
          textAlign: 'left',
          textTransform: 'uppercase',
          borderBottom: `1px solid ${colors.neutral.light}`,
        },
        medium: {
          fontSize: mergedTypography.fontSize.medium,
          fontWeight: mergedTypography.fontWeight.normal,
          fontFamily: mergedTypography.fontFamily.bold,
          padding: `${spacing['5xlarge']} ${spacing.medium} ${spacing.large}`,
          textAlign: 'left',
          textTransform: 'uppercase',
          borderBottom: `1px solid ${colors.neutral.light}`,
        },
        large: {
          fontSize: mergedTypography.fontSize.large,
          fontWeight: mergedTypography.fontWeight.normal,
          fontFamily: mergedTypography.fontFamily.bold,
          padding: `${spacing['5xlarge']} ${spacing.medium} ${spacing.large}`,
          textAlign: 'left',
          textTransform: 'uppercase',
          borderBottom: `1px solid ${colors.neutral.light}`,
        },
      },
      cells: {
        small: {
          fontSize: mergedTypography.fontSize.medium,
          fontFamily: mergedTypography.fontFamily.sans,
          padding: `${spacing.large} ${spacing.small}`,
          borderBottom: `1px solid ${colors.neutral.light}`,
          verticalAlign: 'middle',
        },
        medium: {
          fontSize: mergedTypography.fontSize.medium,
          fontFamily: mergedTypography.fontFamily.sans,
          padding: `${spacing.large} ${spacing.medium}`,
          borderBottom: `1px solid ${colors.neutral.light}`,
          verticalAlign: 'middle',
        },
        large: {
          fontSize: mergedTypography.fontSize.large,
          fontFamily: mergedTypography.fontFamily.sans,
          padding: `${spacing.large} ${spacing.medium}`,
          borderBottom: `1px solid ${colors.neutral.light}`,
          verticalAlign: 'middle',
        },
      },
    } as const,

    sizes: {
      '2xsmall': {
        fontSizeDisplay: mergedTypography.fontSize['3xsmall'],
        fontSizeText: mergedTypography.fontSize['2xsmall'],
        borderRadius: radius.xsmall,
        boxSize: sizing['2xsmall'],
        gap: spacing['2xsmall'],
        paddingX: spacing.xsmall,
        paddingY: spacing['2xsmall'],
      },
      xsmall: {
        fontSizeDisplay: mergedTypography.fontSize['2xsmall'],
        fontSizeText: mergedTypography.fontSize.xsmall,
        borderRadius: radius.xsmall,
        boxSize: sizing.xsmall,
        gap: spacing.xsmall,
        paddingX: spacing.small,
        paddingY: spacing['2xsmall'],
      },
      small: {
        fontSizeDisplay: mergedTypography.fontSize.xsmall,
        fontSizeText: mergedTypography.fontSize.small,
        borderRadius: radius.small,
        boxSize: sizing.small,
        gap: spacing.small,
        paddingX: spacing.medium,
        paddingY: spacing.xsmall,
      },
      medium: {
        fontSizeDisplay: mergedTypography.fontSize.large,
        fontSizeText: mergedTypography.fontSize.medium,
        borderRadius: radius.medium,
        boxSize: sizing.medium,
        gap: spacing.small,
        paddingX: spacing.large,
        paddingY: spacing.small,
      },
      large: {
        fontSizeDisplay: mergedTypography.fontSize.xlarge,
        fontSizeText: mergedTypography.fontSize.large,
        borderRadius: radius.large,
        boxSize: sizing['2xlarge'],
        gap: spacing.medium,
        paddingX: spacing.xlarge,
        paddingY: spacing.medium,
      },
      xlarge: {
        fontSizeDisplay: mergedTypography.fontSize.xlarge,
        fontSizeText: mergedTypography.fontSize.xlarge,
        borderRadius: radius.large,
        boxSize: sizing['2xlarge'],
        gap: spacing.medium,
        paddingX: spacing.xlarge,
        paddingY: spacing.medium,
      },
    },
    colorPairs: {
      hero: {
        foreground: colors.primary,
        background: colors.secondary,
        neutral: colors.neutral,
        ':up': {
          foreground: colors.secondary,
          background: colors.primary,
        },
        ':down': {
          foreground: colors.white,
          background: colors.primary,
        },
        ':disabled': {
          foreground: colors.neutral,
          background: colors.neutral,
        },
      },
      highlight: {
        foreground: colors.white,
        background: colors.secondary,
        neutral: colors.neutral,
        ':up': {
          foreground: colors.secondary,
          background: colors.white,
        },
        ':down': {
          foreground: colors.primary,
          background: colors.white,
        },
        ':disabled': {
          foreground: colors.neutral,
          background: colors.neutral,
        },
      },
      primary: {
        foreground: colors.white,
        background: colors.primary,
        neutral: colors.neutral,
        ':up': {
          foreground: colors.primary,
          background: colors.white,
        },
        ':down': {
          foreground: colors.secondary,
          background: colors.white,
        },
        ':disabled': {
          foreground: colors.neutral,
          background: colors.neutral,
        },
      },
      secondary: {
        foreground: colors.secondary,
        background: colors.white,
        neutral: colors.neutral,
        ':up': {
          foreground: colors.white,
          background: colors.secondary,
        },
        ':down': {
          foreground: colors.primary,
          background: colors.secondary,
        },
        ':disabled': {
          foreground: colors.neutral,
          background: colors.neutral,
        },
      },
      dark: {
        foreground: colors.secondary,
        background: colors.primary,
        neutral: colors.neutral,
        ':up': {
          foreground: colors.primary,
          background: colors.secondary,
        },
        ':down': {
          foreground: colors.white,
          background: colors.secondary,
        },
        ':disabled': {
          foreground: colors.neutral,
          background: colors.neutral,
        },
      },
      light: {
        foreground: colors.primary,
        background: colors.white,
        neutral: colors.neutral,
        ':up': {
          foreground: colors.white,
          background: colors.primary,
        },
        ':down': {
          foreground: colors.secondary,
          background: colors.primary,
        },
        ':disabled': {
          foreground: colors.neutral,
          background: colors.neutral,
        },
      },
      'neutral-dark': {
        foreground: colors['neutral-dark'],
        background: colors['neutral-light'],
        neutral: colors.neutral,
        ':up': {
          foreground: colors.neutral,
          background: colors['neutral-light'],
        },
        ':down': {
          foreground: colors.primary,
          background: colors['neutral-light'],
        },
        ':disabled': {
          foreground: colors.neutral,
          background: colors.neutral,
        },
      },
      default: {
        foreground: colors.white,
        background: colors.neutral,
        neutral: colors.neutral,
        ':up': {
          foreground: colors.neutral,
          background: colors.white,
        },
        ':down': {
          foreground: colors.neutral,
          background: colors.white,
        },
        ':disabled': {
          foreground: colors.neutral,
          background: colors.neutral,
        },
      },
      accent: {
        foreground: colors.white,
        background: colors.tertiary,
        neutral: colors.neutral,
        ':up': {
          foreground: colors.tertiary,
          background: colors.white,
        },
        ':down': {
          foreground: colors.secondary,
          background: colors.white,
        },
        ':disabled': {
          foreground: colors.neutral,
          background: colors.neutral,
        },
      },
      subtle: {
        foreground: colors.tertiary,
        background: colors.white,
        neutral: colors.neutral,
        ':up': {
          foreground: colors.white,
          background: colors.tertiary,
        },
        ':down': {
          foreground: colors.secondary,
          background: colors.white,
        },
        ':disabled': {
          foreground: colors.neutral,
          background: colors.neutral,
        },
      },
      vibrant: {
        foreground: colors.tertiary,
        background: colors.primary,
        neutral: colors.neutral,
        ':up': {
          foreground: colors.primary,
          background: colors.tertiary,
        },
        ':down': {
          foreground: colors.white,
          background: colors.tertiary,
        },
        ':disabled': {
          foreground: colors.neutral,
          background: colors.neutral,
        },
      },
      vivid: {
        foreground: colors.primary,
        background: colors.tertiary,
        neutral: colors.neutral,
        ':up': {
          foreground: colors.primary,
          background: colors.tertiary,
        },
        ':down': {
          foreground: colors.white,
          background: colors.primary,
        },
        ':disabled': {
          foreground: colors.neutral,
          background: colors.neutral,
        },
      },
    } as const,
    // mq: mediaQueries as IMediaBreakpoints,
  };
};
