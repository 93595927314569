import { Button } from '@rugby-au/button';
import { useTheme } from '@rugby-au/theme';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { TextInputProps, KeyboardTypeOptions, TextInputIOSProps, View, ActivityIndicator } from 'react-native';
import { IconTypes } from '@rugby-au/icons';
import { Text } from '@rugby-au/commons';

import { getTextInputStyles } from './styles';
import { TextInputFieldWrapper } from './TextInput';
import { AdditionalMessageProps, FieldRefProps } from './types';
import { autoValidateIn, validateEmail } from './utils';
import { CustomLabel } from './Checkbox';

interface CustomTextInputProps extends Omit<TextInputProps, 'hitSlop'> {
  source?: TextInputProps['hitSlop'];
}

export interface TextInputFieldProps extends CustomTextInputProps {
  /** Label */
  label?: string;
  /** Text Fields type */
  type?: KeyboardTypeOptions | 'password' | 'text' | 'password' | 'email' | 'number' | 'tel' | 'url';
  /** is disabled */
  disabled?: boolean;
  /** mask */
  regex?: string;
  /** Is Required */
  required?: boolean;
  /** auto validate */
  autoValidate?: boolean;
  /** Label Styles */
  labelStyles?: any;
  /** Field Styles */
  fieldStyles?: any;
  /** View Styles */
  containerStyle?: any;
  /** Field Container styles */
  fieldContainerStyle?: any;
  /** textContentType  for IOS*/
  autoCompleteTypeIos?: TextInputIOSProps['textContentType'];
  /** textContentType  for Android*/
  autoCompleteTypeAndroid?: TextInputProps['autoComplete'];
  /** Icon */
  icon?: IconTypes;
  /** Icon alignment */
  iconAlignment?: 'left' | 'right';
  /** invoke in on change */
  onChangeField?: (value: string) => void;
  /** invoke it on key down */
  onKeyDown?: (value: KeyboardEvent) => void;
  /** custom additional validation */
  extraValidation?: (value: string) => { isValid: boolean; error?: string; additionalMessage?: AdditionalMessageProps };
  /**fieldRef */
  fieldRef: FieldRefProps;
  /** autocomplete attribute for web i.e first-name */
  autoCompleteTypeWeb?: string;
  /** mask pattern to display */
  mask?: (value: string) => { displayValue: string; actualValue: string };
  /** This prop is useful whe the text input is part of a greater component like Phone number */
  showError?: boolean;
  /** prefix */
  prefix?: string;
  /** onSubmit to trigger when press enter  */
  onSubmit?: () => void;
  /**input styles override */
  customInputStyles?: any;
  /**html input pattern */
  pattern?: any;
  /** bool to trim value before sunbmit */
  trim?: boolean;
}

export const TextInputField = ({
  label,
  type,
  disabled = false,
  value,
  regex,
  required = false,
  autoValidate = false,
  labelStyles,
  onChangeField,
  onKeyDown,
  extraValidation,
  // fieldStyles = {},
  containerStyle = {},
  fieldContainerStyle = {},
  customInputStyles = {},
  autoCompleteTypeIos = 'none',
  autoCompleteTypeAndroid = 'off',
  icon,
  iconAlignment = 'right',
  fieldRef,
  autoCompleteTypeWeb,
  mask,
  showError = true,
  prefix,
  onSubmit,
  trim,
  ...props
}: TextInputFieldProps) => {
  // Keeping a seperate state for the value to avoid react rendering delays
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [additionalMessage, setAdditionalMessage] = useState<AdditionalMessageProps>();
  const [hasChanged, setHasChanged] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { colors, radius, spacing, typography } = useTheme();
  const ref = useRef<any>(null);

  // On init assign default input value to state
  useEffect(() => {
    if (value && inputValue !== value && !hasChanged) {
      setInputValue(value);
    }
  }, [value, inputValue, hasChanged]);

  const validate = useCallback(async () => {
    setAdditionalMessage(undefined);
    setError('');
    if (required && !inputValue) {
      setIsValid(false);
      setIsError(true);
      if (showError) {
        setError('Field required');
      }
      return false;
    }
    if (type === 'email-address' && !validateEmail(inputValue)) {
      setIsValid(false);
      setIsError(true);
      if (showError) {
        setError('Invalid email address');
      }
      return false;
    }
    if (regex) {
      const re = new RegExp(regex);
      if (!re.test(inputValue.trim())) {
        setIsValid(false);
        setIsError(true);
        if (showError) {
          setError('Invalid input');
        }
        return false;
      }
    }
    if (extraValidation) {
      setIsLoading(true);
      const extraValidationResult = await extraValidation(inputValue);
      if (!extraValidationResult.isValid) {
        setIsValid(false);
        setIsError(true);
        if (extraValidationResult.error) {
          if (showError) {
            setError(extraValidationResult.error);
          }
        }
        if (extraValidationResult.additionalMessage) {
          setAdditionalMessage(extraValidationResult.additionalMessage);
        }
        setIsLoading(false);
        return false;
      }
      setIsLoading(false);
    }
    setIsValid(true);
    setIsError(false);
    setError('');
    return true;
  }, [extraValidation, inputValue, regex, required, showError, type]);

  //if autoValidation is true then the component will be reactive validating every XXX millisencons
  useEffect(() => {
    if (autoValidate) {
      if (hasChanged) {
        timerRef.current = setTimeout(() => {
          validate();
        }, autoValidateIn);
      }
      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    }
  }, [hasChanged, inputValue, validate, autoValidate]);

  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent) => {
      onKeyDown && onKeyDown(event);
    },
    [onKeyDown],
  );

  const handleOnChange = useCallback(
    (text: string) => {
      if (trim) {
        text = text.trim();
      }
      setInputValue(text);
      !hasChanged && setHasChanged(true);
      onChangeField && onChangeField(text);
    },
    [hasChanged, onChangeField, trim],
  );
  useEffect(() => {
    if (fieldRef) {
      fieldRef.current = {
        validate: validate,
        value: inputValue,
        setError: setError,
        setAdditionalMessage: setAdditionalMessage,
        setValue: (_value: string) => {
          setInputValue(_value);
        },
        focus: () => {
          console.log('focus');
          ref.current.focus();
        },
      };
    }
  }, [fieldRef, inputValue, validate]);

  const onBlur = () => {
    if (autoValidate) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      validate();
    }
  };

  const { ids, styles } = getTextInputStyles({ isError, isValid, disabled, colors, radius, spacing, icon, typography });

  const handleMask = (_inputValue: string) => {
    if (_inputValue && mask) {
      const { displayValue, actualValue } = mask(_inputValue);
      if (inputValue !== actualValue) {
        setInputValue(actualValue);
      }
      return displayValue;
    }
    return _inputValue;
  };
  return (
    <>
      <View style={fieldContainerStyle}>
        {label && (
          <Text style={{ ...styles.label, ...labelStyles }}>
            {label} {required && '*'}
          </Text>
        )}
        <TextInputFieldWrapper
          handleOnChange={handleOnChange}
          handleOnKeyDown={handleOnKeyDown}
          value={mask ? handleMask(inputValue) : inputValue}
          autoCompleteTypeIos={autoCompleteTypeIos}
          autoCompleteTypeAndroid={autoCompleteTypeAndroid}
          //keyboardType={type}
          type={type}
          autoCompleteTypeWeb={autoCompleteTypeWeb}
          fieldContainerStyles={{ ...styles.fieldContainer, ...containerStyle }}
          inputStyles={{ ...styles.input, ...customInputStyles }}
          icon={icon}
          iconAlignment={iconAlignment}
          isValid={isValid}
          disabled={disabled || isLoading}
          isError={isError}
          onBlur={onBlur}
          dataMediaInput={ids.input}
          dataMediaContainer={ids.fieldContainer}
          prefix={prefix}
          onSubmitEditing={onSubmit}
          {...props}
          {...{ ref }}
        />
      </View>
      {isLoading && autoValidate && (
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ paddingRight: 10 }}>We are checking your email address</Text>
          <ActivityIndicator size="small" color={colors.primary} />
        </View>
      )}
      {error && <Text style={styles.error}>{error}</Text>}
      {additionalMessage && (
        <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
          {additionalMessage.htmlLabel ? <CustomLabel htmlLabel={additionalMessage.htmlLabel} /> : <Text style={styles.error}>{additionalMessage.firstText}</Text>}
          {additionalMessage.onPress && (
            <View>
              <Button title={additionalMessage.linkText} weight={'none'} onPress={additionalMessage.onPress} style={{ padding: 0, margin: 0, flex: 0 }} />
            </View>
          )}
          {additionalMessage.secondText && <Text style={styles.error}>{additionalMessage.secondText}</Text>}
        </View>
      )}
    </>
  );
};
