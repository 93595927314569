import { ISiteColors, ISpacing } from '@rugby-au/theme';
import StyleSheet from 'react-native-media-query';

export const getFormStyles = ({ colors, spacing }: { colors: ISiteColors; spacing: ISpacing }) => {
  return StyleSheet.create({
    container: { flex: 1, paddingHorizontal: spacing.medium },
    headingContainer: { marginVertical: spacing.large, alignSelf: 'center' },
    formContainer: { flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start' },
    fieldContainer: {
      marginBottom: spacing.large,
      zIndex: -1,
      width: '100%',
      '@media (max-width: 768px)': { width: '100%', marginBottom: spacing.medium, alignContent: 'flex-start' },
    },
    buttonContainer: { flex: 1, alignContent: 'center', marginVertical: spacing.medium },
    error: { color: colors.danger, alignSelf: 'center' },
  });
};
