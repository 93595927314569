import React from 'react';
import { Image as RnImage, ImageProps } from 'react-native';

interface CustomImageProps extends Omit<ImageProps, 'source'> {
  source?: ImageProps['source'];
}
/**
 * This components act as a wrapper for Image in react-native in case we have to add any custom logic
 */
export interface RxImageProps extends CustomImageProps {
  /** Image source */
  url: string;
  /** Image height */
  height?: number;
  /** Image width */
  width?: number;
  /** Image alt  */
  alt: string;
  /** Image aspect ratio */
  aspectRatio?: number;
}

export const Image = ({ ...props }: RxImageProps) => {
  // if (!props.source) {
  //   props.source = {
  //     height: 1,
  //     width: 1,
  //     scale: 1,
  //     uri: '',
  //   };
  // }
  return (
    <RnImage
      style={{ width: '100%', height: '100%', ...(props.aspectRatio ? { aspectRatio: props.aspectRatio as number } : {}) }}
      {...props}
      {...(props.url && props.url !== '' ? { source: { uri: props.url } } : { source: { uri: '' } })}
    />
  );
};
