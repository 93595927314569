import React from 'react';
import { View } from 'react-native';
import { Text } from '@rugby-au/commons';

import { ITheme, useTheme } from '@rugby-au/theme';

import { getBillTotalStyles } from './styles';

export interface BillTotalProps {
  /** Propduct appearance */
  appearance?: ITheme;
  /** Container Styles */
  containerStyles?: any;
  /** Total */
  total?: number | string;
  /** Transaction fee */
  processingPerc?: number;
  /** Children */
  children?: React.ReactNode;
}

export const BillTotal = ({ appearance = 'primary', containerStyles, total, processingPerc, children, ...props }: BillTotalProps) => {
  const { colorPairs, spacing, headings, typography } = useTheme();
  const styles = getBillTotalStyles({
    spacing,
    headings,
    typography,
    colors: colorPairs[appearance],
  });
  return (
    <View {...props} style={{ ...styles.container, ...containerStyles }}>
      <View style={{ ...styles.billTotalContainer, ...containerStyles }}>
        <Text style={{ ...styles.headerText }}>Total</Text>
        {total && <Text style={{ ...styles.headerText }}>{`$${total}`}</Text>}
      </View>
      <View style={{ ...styles.billTotalContainer, ...containerStyles }}>
        {!!processingPerc && <Text style={{ ...styles.infoText }}>{`including ${processingPerc}% transaction fee for online payments`}</Text>}
      </View>
      {children}
    </View>
  );
};
