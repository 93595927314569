import { IColorGroup, ISpacing, ITypography } from '@rugby-au/theme';
import { StyleSheet, TextStyle } from 'react-native';

export const getBillStyles = ({
  colors,
}: // size
{
  spacing: ISpacing;
  headings: any;
  typography: ITypography;
  colors: IColorGroup;
  // size: ISizeGroup;
}) => {
  return StyleSheet.create({
    container: {},
    list: {
      borderWidth: 1,
      borderColor: colors.neutral.light,
      borderRadius: 5,
    },
    listFooter: {
      borderTopWidth: 1,
      borderColor: colors.neutral.light,
    },
  });
};
export const getBillItemStyles = ({
  spacing,
  typography,
  colors,
}: // size
{
  spacing: ISpacing;
  headings: any;
  typography: ITypography;
  colors: IColorGroup;
  // size: ISizeGroup;
}) => {
  // let baseContainer: ViewStyle | TextStyle = {

  // };
  return StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
      //justifyContent: 'space-between',
      // alignItems: 'center',
      // borderWidth: spacing.px,
      // borderColor: colors.neutral.light,
      justifyContent: 'space-between',
      padding: spacing.large,
      // paddingTop: spacing.small,
      // paddingLeft: spacing.medium,
      // paddingBottom: spacing.small,
      // paddingRight: spacing.xlarge,
      backgroundColor: 'white',
    },
    billItemContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    editComponent: {
      padding: spacing.none,
    },
    itemContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    headerText: {
      // ...headings[4],
      fontFamily: typography.fontFamily.semiBold,
      fontSize: typography.fontSize.large,
      marginTop: spacing.xsmall,
      marginBottom: spacing.xsmall,
      color: colors.neutral.dark,
    } as TextStyle,
    itemText: {
      fontFamily: typography.fontFamily.regular,
      fontSize: typography.fontSize.medium,
      marginTop: spacing.xsmall,
      marginBottom: spacing.xsmall,
      color: colors.neutral.dark,
    } as TextStyle,
  });
};

export const getBillTotalStyles = ({ spacing, typography, colors }: { spacing: ISpacing; headings: any; typography: ITypography; colors: IColorGroup }) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
      //justifyContent: 'space-between',
      // alignItems: 'center',
      borderTopWidth: spacing.px,
      borderColor: colors.neutral.light,
      justifyContent: 'space-between',
      paddingTop: spacing.small,
      paddingLeft: spacing.medium,
      paddingBottom: spacing.small,
      paddingRight: spacing.xlarge,
      backgroundColor: 'white',
    },
    billTotalContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    headerText: {
      // ...headings[4],
      fontFamily: typography.fontFamily.semiBold,
      fontSize: typography.fontSize.large,
      marginTop: spacing.xsmall,
      marginBottom: spacing.xsmall,
      color: colors.neutral.dark,
    } as TextStyle,
    infoText: {
      fontFamily: typography.fontFamily.regular,
      fontSize: typography.fontSize.small,
      marginTop: spacing.xsmall,
      marginBottom: spacing.xsmall,
      color: colors.neutral.mid,
    } as TextStyle,
  });
};

export const getBillSubtotalStyles = ({ spacing, headings, typography, colors }: { spacing: ISpacing; headings: any; typography: ITypography; colors: IColorGroup }) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: spacing.small,
      paddingLeft: spacing.medium,
      paddingBottom: spacing.small,
      paddingRight: spacing.xlarge,
      backgroundColor: 'white',
    },
    billSubtotalContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    headerText: {
      ...headings[5],
      fontFamily: typography.fontFamily.regular,
      marginTop: spacing.xsmall,
      marginBottom: spacing.xsmall,
      color: colors.neutral.dark,
    } as TextStyle,
    itemText: {
      ...headings[5],
      fontFamily: typography.fontFamily.regular,
      marginTop: spacing.xsmall,
      marginBottom: spacing.xsmall,
      color: colors.neutral.dark,
    } as TextStyle,
  });
};
