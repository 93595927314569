import React, { createContext } from 'react';
import { FlatList, View } from 'react-native';

import { ITheme, useTheme } from '@rugby-au/theme';

import { CheckboxField } from '@rugby-au/form-fields';
import { BillItem } from './BillItem';
import { getBillStyles } from './styles';
import { BillSubtotal } from './BillSubTotal';
import { BillTotal } from './BillTotal';

type BillItem = {
  id: string;
  name: string;
  price: number;
  items: any[];
  autoRenew: boolean;
  useSameDetails?: boolean;
};

const billData: BillData = {
  billDetail: [],
  subtotal: 0,
  totalDiscount: 0,
  donation: 0,
  total: 0,
  processingFee: 0,
  processingPerc: 0,
};
export const billContext = createContext({ bill: { billData }, setBill: () => {} });

export type BillData = {
  /** product list */
  billDetail: BillItem[];
  /** product totals list */
  subtotal: number;
  totalDiscount: number;
  donation: number;
  total: number | string;
  transactionFee?: number;
  processingFee: number;
  processingPerc: number;
};

export interface BillProps {
  /** Bill appearance */
  appearance?: ITheme;
  /** Container Styles */
  containerStyles?: any;
  bill: BillData;
  setBill: (bill: BillData) => void;
  onEdit?: (id: string) => void;
  onRemove?: (id: string) => void;
  displayAutoRenew?: boolean;
  //billData: BillData;
}

export const Bill = ({
  appearance = 'primary',
  containerStyles,
  bill,
  setBill,
  //billData,
  onEdit,
  onRemove,
  displayAutoRenew = true,
  ...props
}: BillProps) => {
  const { colorPairs, spacing, headings, typography } = useTheme();
  // const billData = useContext(billContext);
  // const bill = billData.bill.billData;
  // console.log(`Bill.tsx line 58 - bill `, bill)

  const styles = getBillStyles({
    spacing,
    headings,
    typography,
    colors: colorPairs[appearance],
  });
  const listSeparator = () => {
    return (
      <View
        style={{
          height: 1,
          backgroundColor: colorPairs[appearance].neutral.light,
        }}
      />
    );
  };

  return (
    // <billContext.Consumer>
    <View
      //onPress={}
      {...props}
      style={{ ...styles.container, ...containerStyles }}>
      <FlatList
        style={styles.list}
        data={bill.billDetail}
        renderItem={({ item }) => (
          <BillItem
            name={item.name}
            price={item.price}
            detail={item.items}
            onEdit={() => {
              onEdit && onEdit(item.id);
            }}
            onRemove={() => {
              onRemove && onRemove(item.id);
            }}>
            {displayAutoRenew && (
              <CheckboxField
                label={'Auto renew membership'}
                onChangeField={value => {
                  bill.billDetail = bill.billDetail.map(detail => {
                    //console.log(`Bill.tsx line 76 - item.id `, item.id, detail.id)
                    if (item.id === detail.id) {
                      detail.autoRenew = value;
                    }
                    return detail;
                  });
                  //console.log(`Bill.tsx line 82 - bill `, bill.billDetail)
                  setBill(bill);
                }}
                // isError={false}
                //error={'This is an error message'}
                align={'left'}
                required={true}
                value={item.autoRenew ?? false}
              />
            )}
          </BillItem>
        )}
        ItemSeparatorComponent={listSeparator}
        keyExtractor={item => item.name}
        ListFooterComponent={() => {
          return (
            <>
              <BillSubtotal subtotal={bill.subtotal} totalDiscount={bill.totalDiscount} donation={bill.donation} processingFee={bill?.processingFee} />
              <BillTotal total={bill.total} processingPerc={bill.processingPerc} />
            </>
          );
        }}
        ListFooterComponentStyle={styles.listFooter}
      />
    </View>
  );
};
