import React, { useRef, useState } from 'react';
import { Modal, View } from 'react-native';
import { Form, FormRefProps } from '@rugby-au/form';
import { Button } from '@rugby-au/button';
import { useTheme } from '@rugby-au/theme';

import { AddressProps } from './AddressField';
import { CountryList } from '../assets/Countries';

const countryOptions = CountryList?.map(country => ({ value: country?.en, label: country?.en }));
const manualAddressFormConfig = {
  fields: [
    { key: 'addressline1', label: 'Address Line 1', required: true, displayType: 'TextInput' as const, autoComplemeteTypeWeb: 'address-line1' },
    { key: 'addressline2', label: 'Address Line 2', required: false, displayType: 'TextInput' as const, autoComplemeteTypeWeb: 'address-line2' },
    { key: 'city', label: 'City', required: true, displayType: 'TextInput' as const, autoComplemeteTypeWeb: 'address-line3' },
    { key: 'state', label: 'State', required: false, displayType: 'TextInput' as const, autoComplemeteTypeWeb: 'address-line4' },
    { key: 'postcode', label: 'Postcode', required: true, displayType: 'TextInput' as const, autoCompleteTypeWeb: 'postal-code' },
    { key: 'zipcode', label: 'Zip Code', required: true, displayType: 'TextInput' as const, autoComplemeteTypeWeb: 'postal-code' },
    {
      key: 'country',
      label: 'Country',
      required: true,
      displayType: 'PickerInput' as const,
      items: [{ value: '', label: '' }, ...countryOptions],
      autoComplete: 'country-name',
    },
  ],
  nationalConfig: {
    RAU: {
      skip: ['zipcode'],
    },
    USA: {
      skip: ['postcode'],
    },
  },
};
/**
 * This component gives the option to add the address manually, by providing a modal with a form
 */
export const ManualAddressField = ({ onAddressChange }: { onAddressChange: (newValue: AddressProps) => void }) => {
  const formRef = useRef<FormRefProps>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const { colors, spacing } = useTheme();

  const handleOnClose = async () => {
    const submitRes = await formRef.current?.onSubmit();
    if (submitRes?.isValid) {
      const data = submitRes?.submitData;
      const addressData: AddressProps = {
        formattedAddress: `${data.addressline1 ?? ''}${data.addressline2 ? `, ${data.addressline2}` : ''} ${data.city ?? ''} ${data.state ?? ''} ${
          data.zipcode ?? data.postcode ?? ''
        } ${data.country ?? ''}`,
        addressline1: data.addressline1,
        addressline2: data.addressline2,
        city: data.city,
        state: data.state,
        postcode: data.zipcode ?? data.postcode,
        country: data.country,
      };
      onAddressChange(addressData);
      setModalVisible(false);
    }
  };
  return (
    <View style={{ marginVertical: spacing.small, alignSelf: 'flex-end' }}>
      <Button
        weight={'none'}
        title={'Enter address manually'}
        onPress={() => {
          setModalVisible(true);
        }}
      />
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false);
        }}>
        <View
          style={{
            // flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <View
            style={{
              backgroundColor: colors.backgroundLight,
              borderRadius: 20,
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 4,
              elevation: 5,
              padding: spacing.medium,
              width: '100%',
              maxWidth: 350,
              flex: 1,
              flexDirection: 'column',
              flexGrow: 1,
            }}>
            <Form formConfig={manualAddressFormConfig} formRef={formRef} />
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginVertical: spacing.medium }}>
              <View>
                <Button
                  title="Submit"
                  onPress={() => {
                    handleOnClose();
                  }}
                />
              </View>
              <View style={{ marginLeft: spacing.medium }}>
                <Button
                  title="Cancel"
                  onPress={() => {
                    setModalVisible(false);
                  }}
                  appearance={'default'}
                  weight={'regular'}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};
