import React, { useRef } from 'react';
import { View } from 'react-native';
import { TextInputField } from '../TextInputField';
import { FieldRefMethodProps } from '../types';

export const QuantityInput = ({
  disabled = false,
  value,
  onChangeField,
  min = 0,
  step = 1,
  max,
}: {
  onChangeField?: (quantity: number) => void;
  disabled: boolean;
  max?: number;
  min: number;
  step: number;
  value: number;
}) => {
  // Keeping a seperate state for the value to avoid react rendering delays
  const textFieldRef = useRef<FieldRefMethodProps>();

  return (
    <View style={{ width: 75, flex: 0 }}>
      <TextInputField
        value={`${value}`}
        fieldRef={textFieldRef}
        onChangeField={(_value: string) => {
          const _parsedValue = parseInt(_value, 10);
          if (_parsedValue >= min && (!max || _parsedValue <= max) && !isNaN(_parsedValue) && _parsedValue !== value) {
            onChangeField && onChangeField(Math.ceil(_parsedValue));
          } else {
            textFieldRef?.current?.setValue?.(`${_value === '' ? _value : value}`);
          }
        }}
        type="number"
        {...{ min: min, max: max, step: step, disabled, pattern: '[0-9]*' }}
      />
    </View>
  );
};
