// import { enAU as RAU, enUS as USA } from 'date-fns/locale';
// import { format } from 'date-fns';
// import { National } from '@rugby-au/app-config/src/defaultAppConfig';

export const autoValidateIn: number = 3000;

//validate Email function
export const validateEmail = (email: string) => {
  var mailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!email.match(mailformat)) {
    return false;
  }
  return true;
};

// const locales = { RAU, USA };
// by providing a default string of 'P' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
// export const formatRX = (date: Date | undefined, nationalId: National = 'RAU', formatStr = 'P') => {
//   if (date) {
//     try {
//       //TODO check if the date comes with time, otherwise add 18:00:00.000 as default time since date-fns formater does not accept date without time
//       const custTime = '18:00:00.000';
//       const custDt = new Date(`${date} ${custTime}`);
//       return format(custDt, formatStr, {
//         locale: locales[nationalId], // or global.__localeId__
//       });
//     } catch (e) {
//       console.log('utils.tsx line 33 - error e ', e);
//       return '';
//     }
//   }
//   return '';
// };

export function supportsEmoji() {
  const ctx: any = document.createElement('canvas').getContext('2d');
  ctx.canvas.width = ctx.canvas.height = 1;
  ctx.fillText('😗', -4, 4);
  return ctx.getImageData(0, 0, 1, 1).data[3] > 0; // Not a transparent pixel
}

export const usePropsWithoutFunctions = (props: any) => {
  return Object.keys(props)
    .filter(key => typeof props[key] !== 'function')
    .reduce((acc: any, key: string) => {
      if (key === 'onBlur') {
        return acc;
      }
      if (key === 'disabled') {
        acc[key] = props[key] === 'true';
        return acc;
      }
      acc[key.toLowerCase()] = props[key]?.toString();
      return acc;
    }, {});
};
