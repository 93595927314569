import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { Button } from '@rugby-au/button';
import { ITheme, useTheme } from '@rugby-au/theme';
import { Text } from '@rugby-au/commons';

import { getBillItemStyles } from './styles';
import { Icons } from '@rugby-au/icons';

type Detail = {
  name: string;
  price?: number;
  qty?: number;
};
type DiscountDetail = {
  name: string;
  discount: number;
};

export interface BillItemProps {
  /** Propduct appearance */
  appearance?: ITheme;
  /** Container Styles */
  containerStyles?: any;
  /** product name */
  name: string;
  /** product details list */
  detail: Detail[];
  /** product total price of Items */
  totalItems?: number;
  /** product price */
  price?: number;
  /** product discounts */
  discounts?: DiscountDetail[];
  /** product total = price - discounts */
  total?: number | string;
  /** product onEdit function */
  onEdit?: () => void;
  /**Product on remove function */
  onRemove?: () => void;
  /** Children */
  children?: React.ReactNode;
}

export const BillItem = ({ appearance = 'primary', containerStyles, name, detail, price, onEdit, onRemove, children, ...props }: BillItemProps) => {
  const { colorPairs, colors, spacing, headings, typography } = useTheme();
  const styles = getBillItemStyles({
    spacing,
    headings,
    typography,
    colors: colorPairs[appearance],
  });
  return (
    <View {...props} style={{ ...styles.container, ...containerStyles }}>
      <View style={styles.billItemContainer}>
        {name && <Text style={{ ...styles.headerText }}>{name}</Text>}
        {price && <Text style={{ ...styles.headerText, fontFamily: typography.fontFamily.regular }}>{`$${price}`}</Text>}
      </View>
      {detail &&
        detail.map((item, index) => (
          <View key={index + item.name} style={{ ...styles.itemContainer }}>
            <Text style={{ ...styles.itemText }}>{`${item.qty} x ${item.name}`}</Text>
            {item.price && <Text style={{ ...styles.itemText }}>{`${item.price}`}</Text>}
            <View>
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                {onRemove && index === 0 && (
                  <View style={{ ...styles.editComponent, paddingRight: spacing.large }}>
                    {/* <Button title="Remove" onPress={onRemove} weight={'underline'} appearance={appearance} /> */}
                    <TouchableOpacity style={{ width: 14 }} onPress={onRemove}>
                      <Icons type={'trash'} color={colors.danger} />
                    </TouchableOpacity>
                  </View>
                )}
                {onEdit && index === 0 && (
                  <View style={{ ...styles.editComponent }}>
                    <Button title="Edit" onPress={onEdit} weight={'underline'} appearance={appearance} />
                  </View>
                )}
              </View>
            </View>
          </View>
        ))}
      <View style={{ paddingTop: spacing.medium }}>{children}</View>
    </View>
  );
};
