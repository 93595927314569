// import { useAppConfig } from '@rugby-au/app-config';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { Text } from '@rugby-au/commons';

import { useTheme } from '@rugby-au/theme';
import { autoValidateIn } from '../utils';

import moment from 'moment';
import { FieldRefMethodProps, FieldRefProps } from '../types';
import { DatePickerWrapper } from './DatePicker';
import { getDatePickerFieldStyles } from './styles';
import { useAppConfig } from '@rugby-au/app-config';

export interface DatePickerFieldProps {
  /** Label */
  label?: string;
  /** Date value */
  value?: string;
  // /** placeholder */
  // placeholder?: string;
  /** is disabled */
  disabled?: boolean;
  /** invoke in on change */
  onChangeField?: (value: string | undefined) => void;
  /** Error message */
  required?: boolean;
  /** auto validate */
  autoValidate?: boolean;
  /** auto validate time */
  autoValidateTime?: number;
  //autocomplete?: string;
  labelStyles?: any;
  /** Field Styles */
  fieldStyles?: any;
  /** Container Styles */
  containerStyles?: any;
  /** Editable */
  editable?: boolean;
  /** moment Format */
  format?: string;
  /** minimum date */
  minDate?: Date;
  /** maximum date */
  maxDate?: Date;
  /** maximum years  in the past, useful for birthday min age*/
  maxYears?: number;
  /** Error message for minimum years */
  maxYearsError?: string;
  /** minimum years  in the past, useful for birthday max age*/
  minYears?: number;
  /** Error message for minimum years */
  minYearsError?: string;
  /**fieldRef */
  fieldRef: FieldRefProps;
  /** Display date format inline with label */
  displayDateFormat?: boolean;
}

export const DatePickerField = ({
  label,
  value,
  disabled = false,
  onChangeField,
  required = false,
  autoValidate = false,
  autoValidateTime = autoValidateIn,
  labelStyles,
  // fieldStyles = {},
  // containerStyles,
  // editable = true,
  format = 'L',
  minDate,
  maxDate,
  maxYears,
  maxYearsError,
  minYears,
  minYearsError,
  fieldRef,
  displayDateFormat = false,
}: // ...props
DatePickerFieldProps) => {
  // Keeping a seperate state for the value to avoid react rendering delays
  const [inputValue, setInputValue] = useState<string | undefined>(value);
  const [displayValue, setDisplayValue] = useState<string>(value ?? '');
  const [isValid, setIsValid] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [hasChanged, setHasChanged] = useState(value !== undefined);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { colors, radius, spacing, typography } = useTheme();
  let ref = useRef<FieldRefMethodProps>(null);
  const [dateformat, setDateformat] = useState<string | undefined>(undefined);
  const { nationalId, nationalConfig } = useAppConfig();
  //const nationalId: National = appConfig?.nationalId ? appConfig.nationalId : 'RAU';
  // On init assign default input value to state
  useEffect(() => {
    if (nationalConfig?.config?.general?.dateformat) {
      setDateformat(nationalConfig.config.general.dateformat);
    }

    // else {
    //   getConfig('config.general.dateformat', nationalId ?? undefined).then(_dateformat => {
    //     _dateformat && setDateformat(_dateformat);
    //   });
    // }
  }, [nationalId, nationalConfig]);

  useEffect(() => {
    if (inputValue !== value && !inputValue) {
      setInputValue(value);
      setDisplayValue(value ?? '');
    }
  }, [inputValue, value]);

  const validate = useCallback(() => {
    if (required && !inputValue) {
      setIsValid(false);
      setIsError(true);
      setError('Field required');
      return false;
    }
    if (inputValue && minDate && new Date(inputValue) < minDate) {
      setIsValid(false);
      setIsError(true);
      setError('Date must be after ' + moment(minDate).format(format));
      return false;
    }
    if (inputValue && maxDate && new Date(inputValue) > maxDate) {
      setIsValid(false);
      setIsError(true);
      setError('Date must be before ' + moment(maxDate).format(format));
      return false;
    }
    //user maxYears to calculate minDate and validate
    if (inputValue && maxYears) {
      const _maxDate = moment().subtract(maxYears, 'years');
      if (new Date(inputValue) > _maxDate.toDate()) {
        setIsValid(false);
        setIsError(true);
        setError(maxYearsError ?? `Date must be at least ${maxYears} years ago`);
        return false;
      }
    }
    if (inputValue && minYears) {
      const _minDate = moment().subtract(minYears, 'years');
      if (new Date(inputValue) < _minDate.toDate()) {
        setIsValid(false);
        setIsError(true);
        setError(minYearsError ?? `Date must be at most ${minYears} years ago`);
        return false;
      }
    }
    setIsValid(true);
    setIsError(false);
    setError('');
    return true;
  }, [required, inputValue, minDate, maxDate, maxYears, minYears, format, maxYearsError, minYearsError]);

  useEffect(() => {
    if (autoValidate) {
      if (hasChanged) {
        timerRef.current = setTimeout(() => {
          validate();
        }, autoValidateTime);
      }
      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    }
  }, [autoValidate, hasChanged, inputValue, validate, autoValidateTime]);

  fieldRef.current = {
    validate: validate,
    // value: inputValue, //formatRX(inputValue, nationalId),
    value: inputValue ? moment(inputValue, ['DD/MM/YYYY', 'DD-MM-YYYY', 'MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD']).format('DD/MM/YYYY') : null, //dateformat?.toUpperCase()
    //Dear Dev, This is changed to return always DD/MM/YYYY format to make sure sign up returns the same format when saving to DB
  };

  const handleOnChange = (_value: string | undefined) => {
    // console.log('🚀 ~ file: DatePickerField.tsx:136 ~ handleOnChange ~ _value', _value);
    setInputValue(_value);
    setDisplayValue(_value ?? '');

    setHasChanged(true);
    onChangeField && onChangeField(inputValue);
  };

  const { ids, styles } = getDatePickerFieldStyles({ isError, isValid, disabled, colors, radius, spacing, /*clearIcon: inputValue && hasChanged,*/ typography });

  return (
    <View>
      {!!label && (
        <View style={{ flexDirection: 'row', alignItems: 'center', gap: spacing['xsmall'] }}>
          <Text style={{ ...styles.label, ...labelStyles }}>{label}</Text>
          <Text style={{ ...styles.label, ...labelStyles, fontSize: typography.fontSize['2xsmall'], fontFamily: typography.fontFamily.regular }}>
            {displayDateFormat ? `(${dateformat?.toUpperCase()})` : ''}
          </Text>
          <Text style={{ ...styles.label, ...labelStyles }}>{required && '* '}</Text>
        </View>
      )}

      <View>
        <DatePickerWrapper
          fieldRef={ref}
          styles={styles}
          dateformat={dateformat}
          inputValue={inputValue}
          hasChanged={hasChanged}
          displayValue={displayValue}
          isValid={isValid}
          isError={isError}
          handleOnChange={handleOnChange}
          dataSet={ids.fieldContainer}
          fieldWrapperStyles={styles.fieldContainer}
          disabled={disabled}
        />
      </View>
      {!!error && <Text style={styles.error}>{error}</Text>}
    </View>
  );
};
