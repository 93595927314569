import React, { ReactNode } from 'react';
import { Text as RNText, TextProps, StyleSheet } from 'react-native';

import { ISiteColors, ITypography, useTheme } from '@rugby-au/theme';

/**
 * A Common Text component to set typography styles
 * @param param0
 * @returns
 */
export const Text = ({ children, ...props }: TextProps & { children: ReactNode }) => {
  const { typography, colors } = useTheme();

  const styles = getTextStyles({ colors, typography });

  return (
    <RNText {...props} style={[styles.textStyles, props.style]}>
      {children}
    </RNText>
  );
};

const getTextStyles = ({ colors, typography }: { colors: ISiteColors; typography: ITypography }) => {
  return StyleSheet.create({
    textStyles: {
      fontFamily: typography.fontFamily.regular,
      fontSize: typography.fontSize.medium,
      color: colors.neutralDark,
    },
  });
};
