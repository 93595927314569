import { IColorGroup, IRadius, ISiteColors, ISizeGroup, ISpacing, ITypography } from '@rugby-au/theme';
import { StyleSheet } from 'react-native';

export const getHeadingStyles = () => {
  return StyleSheet.create({ header: { zIndex: 100 } });
};
export const getDesktopHeaderStyles = ({ spacing, colors }: { colors: IColorGroup; spacing: ISpacing }) => {
  return StyleSheet.create({
    //@ts-ignore
    primaryContainer: { flex: 1, backgroundColor: colors.background.mid, flexDirection: 'row', paddingHorizontal: spacing.xlarge, minHeight: '102px' },
    container: { height: '100%', flexDirection: 'row', justifyContent: 'flex-end', paddingVertical: spacing.medium },
    crestContainer: { flex: 2, justifyContent: 'center', alignContent: 'center' },
    crestStyles: { resizeMode: 'contain', height: '100%' },
    navItemsContainer: { flex: 12, justifyContent: 'flex-end', flexDirection: 'row', paddingVertical: spacing.medium },
  });
};

export const getNavItemStyles = ({
  spacing,
  colors,
  typography,
  subNavColors,
  isHover,
  isButton,
}: {
  colors: IColorGroup;
  spacing: ISpacing;
  typography: ITypography;
  subNavColors: IColorGroup;
  isHover: null | boolean;
  isButton: boolean;
}) => {
  return StyleSheet.create({
    container: {
      position: 'relative' as 'relative',
      color: isHover ? colors.background.mid : colors?.foreground?.mid,
      justifyContent: 'center',
      alignContent: 'center',
      backgroundColor: isButton ? 'transparent' : isHover ? colors.foreground.mid : colors?.background?.mid,
      marginLeft: spacing.small,
    },
    linkContainer: {
      color: isHover ? colors.background.mid : colors?.foreground?.mid,
      fontFamily: typography.fontFamily.semiBold,
      fontSize: typography.fontSize.xsmall,
      textTransform: 'uppercase',
      overflow: 'hidden',
    },
    subNavContainer: {
      position: 'absolute',
      margin: 0,
      padding: spacing.large,
      color: subNavColors?.foreground?.mid,
      backgroundColor: subNavColors?.[':down']?.foreground?.mid,
      right: 0,
      // @ts-ignore as this styles are web focused
      display: isHover ? 'block' : 'none',
      marginBlockEnd: 0,
      marginBlockStart: 0,
      zIndex: isHover ? 25 : 1,
    },
    subNavLink: {
      // @ts-ignore as this styles are web focused
      display: 'block',
      color: subNavColors?.foreground?.mid,
      backgroundColor: subNavColors?.[':down']?.foreground?.mid,
      padding: spacing.large,
    },
    subNavText: {
      position: 'relative',
      color: subNavColors?.foreground?.mid,
      fontFamily: typography.fontFamily.bold,
      fontSize: typography.fontSize.large,
      textTransform: 'capitalize',
      // @ts-ignore as this styles are web focused
      display: 'inline-block',
      whiteSpace: 'nowrap',
      zIndex: 25,
    },
  });
};

export const getMobileHeaderStyles = ({ spacing, colors }: { colors: IColorGroup; spacing: ISpacing }) => {
  return StyleSheet.create({
    primaryContainer: { flex: 1, backgroundColor: colors.background.mid, flexDirection: 'row', minHeight: 60 },
    container: { height: '100%', flexDirection: 'row', justifyContent: 'flex-end', paddingVertical: spacing.xsmall },
    crestContainer: { flex: 2.5 },
    crestStyles: { resizeMode: 'contain', height: '100%' },
    navItemsContainer: { justifyContent: 'flex-end', flexDirection: 'row', flex: 5, paddingVertical: spacing.small },
    menuIconContainer: { justifyContent: 'center' },
  });
};

export const getNavBarStyles = ({
  spacing,
  colors,
  typography,
  subNavColors,
  isSubNavOpen,
}: {
  colors: IColorGroup;
  spacing: ISpacing;
  typography: ITypography;
  subNavColors: IColorGroup;
  isSubNavOpen: boolean;
}) => {
  return StyleSheet.create({
    navBarItem: { padding: spacing['2xlarge'], marginBottom: isSubNavOpen ? 0 : spacing.xsmall, backgroundColor: colors.background.mid },
    navItemTitle: {
      color: colors?.foreground?.mid,
      fontFamily: typography.fontFamily.bold,
      fontSize: typography.fontSize.large,
      textTransform: 'capitalize',
    },
    subNavContainer: { backgroundColor: subNavColors?.[':down']?.foreground.mid, padding: spacing['2xlarge'], marginBottom: spacing['2xsmall'] },
    subNavTitle: {
      color: subNavColors?.foreground?.mid,
      fontFamily: typography.fontFamily.bold,
      fontSize: typography.fontSize.large,
      textTransform: 'capitalize',
    },
  });
};

export const getCartNavIconStyles = ({
  spacing,
  navColors,
  colors,
  radius,
  size,
  isMobile,
  typography,
}: {
  colors: ISiteColors;
  navColors: IColorGroup;
  radius: IRadius;
  spacing: ISpacing;
  size: ISizeGroup;
  isMobile: boolean;
  typography: ITypography;
}) => {
  return StyleSheet.create({
    cartCountContainer: {
      position: 'absolute',
      right: spacing.xsmall,
      top: spacing.xsmall,
      borderRadius: radius.full,
      backgroundColor: colors.dangerLight,
      padding: isMobile ? spacing.px : spacing['2xsmall'],
      width: isMobile ? 15 : size.boxSize - 4,
      height: isMobile ? 15 : size.boxSize - 4,
      opacity: 0.9,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    cartCountText: { color: navColors.foreground.mid, alignSelf: 'center', fontSize: isMobile ? typography.fontSize['2xsmall'] : typography.fontSize['2xsmall'] },
  });
};

export const getCartSubNavStyles = ({ spacing, subNavColors, isHover }: { spacing: ISpacing; subNavColors: IColorGroup; isHover: boolean }) => {
  return StyleSheet.create({
    cartSubNav: {
      position: 'absolute',
      margin: 0,
      padding: spacing.large,
      backgroundColor: subNavColors?.[':down']?.foreground?.mid,
      right: 0,
      marginBottom: 0,
      marginTop: 0,
      zIndex: isHover ? 30 : 1,
      width: 300,
    },
  });
};
