export const authHeader = {
  siteLogo: {
    alt: 'horizontal white',
    url: 'https://d26phqdbpt0w91.cloudfront.net/NonVideo/3f51c965-17cc-4eb3-c97f-08db872b653e.svg',
    width: 129,
    height: 12,
    crestNavPath: '/',
  },
  globalNav: { navItems: [], appearance: 'primary' as const },
  appearance: 'primary' as const,
  primaryNav: {
    navItems: [],
    appearance: 'primary' as const,
  },
  quickAccessNav: { navItems: [], appearance: 'primary' as const, displayCart: false, displayUserOptions: false },
  deviceNavAppearance: 'primary' as const,
};
