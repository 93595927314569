import { IconTypes } from '@rugby-au/icons';
import { ISiteColors, IRadius, ISpacing, ITypography } from '@rugby-au/theme';
import { TextStyle } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const getTextInputStyles = ({
  isError,
  isValid,
  disabled,
  colors,
  radius,
  spacing,
  icon,
  typography,
}: {
  isError?: boolean;
  isValid?: boolean;
  disabled: boolean;
  colors: ISiteColors;
  radius: IRadius;
  spacing: ISpacing;
  icon?: IconTypes;
  typography: ITypography;
}) => {
  let iconWidth = 0;
  if (icon) {
    iconWidth = 10;
  }
  if (isValid || isError) {
    iconWidth += 10;
  }
  const container: {
    backgroundColor?: string;
  } = {
    //background: disabled ? colors.neutral : colors.primaryText,
    backgroundColor: disabled ? colors.neutralExtraLight : colors.primaryText,
  };
  // if (Platform.OS === 'web') {
  //   delete container.backgroundColor;
  // }

  return StyleSheet.create({
    fieldContainer: {
      ...container,
      boxSizing: 'border-box',
      flex: 1,
      borderWidth: 1,
      borderColor: isError ? colors.danger : colors.neutralLight,
      borderRadius: radius.medium,
      alignSelf: 'stretch',
      flexGrow: 1,
      padding: spacing.small,
      '@media (min-width: 768px)': { padding: spacing.medium },
      flexDirection: 'row',
      alignItems: 'center',
    },
    label: { color: colors.neutralDark, marginBottom: spacing.small, fontFamily: typography.fontFamily.semiBold },
    field: { width: `${100 - iconWidth}%` },
    input: {
      fontSize: typography.fontSize.large,
      '@media (min-width: 768px)': { fontSize: typography.fontSize.medium },
      fontFamily: typography.fontFamily.regular,
      color: colors.neutralDark,
    },
    error: { color: colors.danger },
    labelContainer: {
      flex: 1,
      flexDirection: 'column',
      marginBottom: spacing.small,
      '@media (min-width: 768px)': { flexDirection: 'row', marginBottom: 0 },
      alignContent: 'center',
      gap: spacing.small,
    },
    labelInfoText: { color: colors.neutral, fontSize: typography.fontSize.xsmall },
  });
};

export const getPhoneInputStyles = ({ colors, spacing, typography }: { colors: ISiteColors; spacing: ISpacing; typography: ITypography }) => {
  return StyleSheet.create({
    fieldContainer: {
      flex: 1,
      padding: spacing.none,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    label: { color: colors.neutralDark, marginBottom: spacing.small, fontFamily: typography.fontFamily.semiBold },
    field: { width: '100%' },
    error: { color: colors.danger },
    picker: {
      paddingRight: spacing.small,
    },
    text: {
      width: '70%',
      flex: 1,
    },
  });
};

export const getCheckboxInputStyles = ({
  colors,
  spacing,
  align,
  typography,
}: {
  colors: ISiteColors;
  spacing: ISpacing;
  align: 'left' | 'right';
  typography: ITypography;
}) => {
  return StyleSheet.create({
    checkboxContainer: {
      flex: 1,
      flexDirection: align === 'left' ? 'row' : 'row-reverse',
      // direction: align === 'left' ? 'ltr' : 'rtl',
      ...(align === 'right' ? { justifyContent: 'space-between' } : {}),
      alignItems: 'center',
    },
    mainContainer: { width: '100%' },
    label: {
      color: colors.neutral,
      fontSize: typography.fontSize.large,
      '@media (min-width: 768px)': { fontSize: typography.fontSize.medium },
      fontFamily: typography.fontFamily.regular,
    },
    labelContainer: { flex: 1, ...(align === 'left' ? { marginLeft: spacing.medium } : {}) },
    errorContainer: { flex: 1, paddingTop: spacing.medium },
    errorText: { color: colors.danger },
  });
};

export const getRadioButtonFieldStyles = ({
  colors,
  spacing,
  align = 'left',
  typography,
}: {
  colors: ISiteColors;
  spacing: ISpacing;
  align?: 'left' | 'right';
  typography: ITypography;
}) => {
  return StyleSheet.create({
    radioContainer: {
      flex: 1,
      alignItems: 'center',
      flexDirection: align === 'left' ? 'row' : 'row-reverse',
      // direction: align === 'left' ? 'ltr' : 'rtl',
      ...(align === 'left' ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }),
      marginVertical: spacing.small,
    },
    radioLabel: { color: colors.neutral, marginLeft: align === 'right' ? spacing.medium : 0, flex: 1 },
    label: { fontFamily: typography.fontFamily.semiBold, color: colors.neutralDark, marginBottom: spacing.small } as TextStyle,
    groupContainer: { flex: 1, justifyContent: 'space-around' },
    errorContainer: { flex: 1, paddingTop: spacing.medium },
    errorText: { color: colors.danger },
    radioLabelContainer: {
      ...(align === 'right' ? { flex: 1, alignSelf: 'stretch', justifyContent: 'space-between' } : {}),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    radioLabelIconsContainer: { justifyContent: 'space-around', flexDirection: 'row', ...(align === 'left' ? { paddingLeft: spacing.large } : {}) },
  });
};

export const getQuantityFieldStyles = ({
  isError,
  // disabled,
  colors,
  spacing,
  radius,
  typography,
}: {
  isError: boolean;
  disabled: boolean;
  colors: ISiteColors;
  spacing: ISpacing;
  radius: IRadius;
  typography: ITypography;
}) => {
  return StyleSheet.create({
    fieldContainer: {
      boxSizing: 'border-box',
      flex: 1,
      borderWidth: 1,
      borderColor: isError ? colors.danger : colors.neutralLight,
      borderRadius: radius.medium,
      alignSelf: 'stretch',
      flexGrow: 1,
      padding: spacing.medium,
      flexDirection: 'row',
      alignItems: 'center',
    },
    label: { color: colors.neutralDark, marginBottom: spacing.small, fontFamily: typography.fontFamily.semiBold } as TextStyle,
    error: { color: colors.danger },
  });
};

export const getLabelFieldStyles = ({ colors, typography }: { colors: ISiteColors; typography: ITypography }) => {
  return StyleSheet.create({
    label: {
      color: colors.neutral,
      fontSize: typography.fontSize.medium,
      '@media (min-width: 768px)': { fontSize: typography.fontSize.medium },
      fontFamily: typography.fontFamily.regular,
    },
  });
};
