import AsyncStorage from '@react-native-async-storage/async-storage';
import isEqual from 'lodash/isEqual';
import { Platform } from 'react-native';

interface Cache {
  [key: string]: any;
}

const cache: Cache = {};

function getCachedData(key: string): any {
  // console.log('localStorage.ts line 13 - cache ', key, cache, cache[key.toString()]);
  return cache[key.toString()];
}

function cacheData(key: string, value: any): void {
  cache[key] = value;
}

function compareObj(expected: object, actual: string): boolean {
  return typeof expected === 'string' ? expected === actual : isEqual(expected, JSON.parse(actual));
}
export const clearAll = async () => {
  try {
    await AsyncStorage.clear();
  } catch (e) {
    // clear error
  }
};

export const getItem = async (key: string): Promise<any> => {
  try {
    let item: any;
    if (typeof window !== 'undefined') {
      item = await AsyncStorage.getItem(key);
    } else {
      item = getCachedData(key);
    }
    try {
      item = item ? JSON.parse(item) : null;
    } catch (e) {}
    return item;
  } catch (e) {
    return undefined;
  }
};

export const setItem = async (key: string, value: any) => {
  try {
    if (typeof window !== 'undefined') {
      await AsyncStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
      const actual = await AsyncStorage.getItem(key);
      if (!compareObj(value, actual || '')) {
        throw [value, actual];
      }
    } else {
      cacheData(key, value);
      // console.log(`localStorage.ts line 58 - cache`, JSON.stringify(cache, null, 2));
    }
  } catch (e) {
    console.log('localStorage.ts line 22 - e ', e, key, value);
  }
};

export const removeItem = async (key: string) => {
  try {
    if (typeof window !== 'undefined') {
      await AsyncStorage.removeItem(key);
    } else {
      delete cache[key];
    }
  } catch (e) {
    console.error('localStorage.ts line 37 - e ', e);
  }
};

//Todo this function should be in a file xxx.js and xxx.native.js
export const setSecureItem = async (key: string, value: any) => {
  if (Platform.OS === 'web') {
    //TODO - try to replace this with a more secure storage like web worker or service worker
    await setItem(key, value);
  } else if (Platform.OS === 'android') {
    //Use react-native-encrypted-storage for EncryptedSharedPreferences
  } else if (Platform.OS === 'ios') {
    //Use react-native-encrypted-storage for KeyChain
  }
};

export const getSecureItem = async (key: string) => {
  if (Platform.OS === 'web') {
    return await getItem(key);
  } else if (Platform.OS === 'android') {
    //Use react-native-encrypted-storage for EncryptedSharedPreferences
  } else if (Platform.OS === 'ios') {
    //Use react-native-encrypted-storage for KeyChain
  }
};

export const fetchCachedResponse = async (key: string) => {
  let item: any = await getItem(key);

  if (!item) {
    return;
  }
  if (typeof item === 'string') {
    item = JSON.parse(item);
  }
  if (item.cacheExpiration <= Date.now()) {
    removeItem(key);
    return;
  }
  return item.response;
};

export const cacheResponse = async (key: string, response: any, cacheExpiration: number) => {
  await setItem(
    key,
    JSON.stringify({
      response,
      cacheExpiration: Date.now() + cacheExpiration * 1000,
    }),
  );
};
