import React from 'react';
import { usePropsWithoutFunctions } from './utils';

export const CheckboxFieldWrapper = ({ disabled, toggleCheckBox, ...props }: { isError: boolean; disabled: boolean; toggleCheckBox: boolean; [key: string]: any }) => {
  const cleanProps = usePropsWithoutFunctions(props);
  return (
    <label
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <input
        style={{
          appearance: 'checkbox',
          transform: 'scale(1.5)',
        }}
        type={'checkbox'}
        disabled={disabled}
        checked={toggleCheckBox}
        onChange={() => {}}
        {...cleanProps}
      />
    </label>
  );
};

// uses react-native-render-html
export const CustomLabel = ({ htmlLabel, tagsStyles, dataMedia }: { htmlLabel: string; tagsStyles?: any; dataMedia?: string }) => {
  return <div {...(dataMedia ? { ['data-media']: dataMedia } : {})} dangerouslySetInnerHTML={{ __html: htmlLabel }} style={tagsStyles} />;
};
