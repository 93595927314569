import React, { createRef, MutableRefObject, useState } from 'react';
import { View } from 'react-native';
import { RadioButtonField } from '@rugby-au/form-fields';
import { PinPayment } from './PinPayment';
import { useTheme } from '@rugby-au/theme';
import { Button } from '@rugby-au/button';
import { Text } from '@rugby-au/commons';

//TODO: get this from the API
const defaultPayment = {
  RAU: ['pin'],
  USA: ['stripe'],
};

const getPaymentOption = ({ paymentOption, paymentRef }: { paymentOption: string; paymentRef: MutableRefObject<PaymentRefProps | null> }) => {
  switch (paymentOption) {
    case 'pin':
      return { displayName: 'Card', component: <PinPayment displayButton={false} paymentRef={paymentRef} /> };
    case 'zip':
      return { displayName: 'Zip - Buy now, pay later', component: <></> };
    case 'stripe':
      return { displayName: 'Card', component: <></> };
    default:
      return { displayName: '', component: <></> };
  }
};

export type PaymentRefProps = { triggerPayment: () => Promise<string | undefined> };

/** This component displays payment options according to the nationalId */
export const Payment = ({
  nationalId,
  displayButton = true,
  title = 'Payment',
  paymentRef,
}: {
  nationalId: string;
  displayButton?: boolean;
  title?: string;
  paymentRef?: MutableRefObject<PaymentRefProps | null>;
}) => {
  const paymentOptions = defaultPayment[nationalId.toUpperCase() as keyof typeof defaultPayment] ?? [];
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(0);

  const { spacing, colors, typography } = useTheme();
  const _paymentRef = paymentRef ?? createRef<PaymentRefProps | null>();
  const handleOnPay = () => {
    try {
      if (_paymentRef.current) {
        _paymentRef.current.triggerPayment();
      }
    } catch (error) {
      console.error('Error in handleOnPay', error);
    }
  };
  return (
    <View>
      {title && title !== '' && <Text style={{ fontSize: typography.fontSize.large, marginBottom: spacing.large }}>{title}</Text>}
      {paymentOptions.map((paymentOption, index) => {
        const option = getPaymentOption({ paymentOption, paymentRef: _paymentRef });
        const isFirst = index === 0;
        return (
          <View key={paymentOption} style={{ borderColor: colors.neutralLight, borderWidth: 1, borderTopRightRadius: isFirst ? 5 : 0, borderTopLeftRadius: isFirst ? 5 : 0 }}>
            <View
              style={{
                paddingVertical: spacing.small,
                paddingHorizontal: spacing['2xlarge'],
                backgroundColor: colors.primaryText,
                borderTopRightRadius: isFirst ? 5 : 0,
                borderTopLeftRadius: isFirst ? 5 : 0,
              }}>
              <RadioButtonField
                align="right"
                radioLabel={option.displayName}
                value={index === selectedPaymentOption ? true : false}
                onChangeField={(value: boolean) => {
                  if (value) {
                    setSelectedPaymentOption(index);
                  }
                }}
                radioLabelIcons={['visa', 'mastercard']}
              />
            </View>
            {index === selectedPaymentOption && <View style={{ padding: spacing.medium }}>{option.component}</View>}
          </View>
        );
      })}
      {displayButton && (
        <View style={{ flex: 1, flexDirection: 'row', paddingTop: spacing.large }}>
          <View>
            <Button
              onPress={() => {
                handleOnPay();
              }}
              title={'Pay'}
            />
          </View>
        </View>
      )}
    </View>
  );
};
