import React from 'react';
import * as iconsMap from './assets/*.svg';
import { IconTypes } from './types';

export interface IconProps {
  height?: number;
  width?: number;
  type: IconTypes;
  color: string;
}

const getIcon = (type: string, iconProps: IconProps) => {
  let IconComponent = (iconsMap as any)[type] ?? undefined;
  if (IconComponent) {
    return <IconComponent {...iconProps} style={{ color: iconProps?.color ?? 'currentColor', fontSize: iconProps?.height ?? iconProps?.width ?? 'inherit' }} />;
  } else {
    return <></>;
  }
};

export const Icons = (props: IconProps) => {
  return getIcon(props.type, props) ?? <></>;
};
