import { getItem, setItem } from './localStorage';

export interface CartItemProps {
  id: string;
  name: string;
  types: CartItemTypeProps[];
  formData?: { __productType: string; [key: string]: any }[];
  autoRenew?: boolean;
}
export interface CartProps {
  items: CartItemProps[];
  meta: { cartId?: string; cartExpiry?: number };
}
export interface CartItemTypeProps {
  id: string;
  name: string;
  price: string;
  quantity: number;
  useSameDetails: boolean;
}

interface ErrorProps {
  error: string;
}

/**
 * Common Key for cart storage
 */
const CART_KEY = 'membership_cart';

/**
 * Add cart item to cart list
 * @param cartItem
 * @returns
 */
export const setCartItem = async (cartItem: CartItemProps) => {
  try {
    const currentCart = await getItem(CART_KEY);
    if (currentCart) {
      const newCart = [...currentCart.items, cartItem];
      await setCart({ ...currentCart, items: newCart });
    } else {
      await setCart({ items: [cartItem], meta: {} });
    }
  } catch (error: any) {
    return { error: error.message };
  }
};
export const setCart = async (cart: CartProps) => {
  try {
    const cartData = await setItem(CART_KEY, cart);
    return cartData;
  } catch (error: any) {
    return { error: error.message };
  }
};

/**
 * Returns cart list
 * @returns CartProps
 */
export const getCart = async (): Promise<CartProps> => {
  try {
    const cart = await getItem(CART_KEY);
    return cart;
    // const currentTime = new Date().getTime();
    // if (cart && cart.items && cart.meta.cartExpiry && cart.meta.cartExpiry > currentTime) {
    //   return cart;
    // } else {
    //   await clearCart();
    //   const newCart = { items: [], meta: {} };
    //   setCart(newCart);
    //   return newCart;
    // }
  } catch (error: any) {
    // return { error: error.message };
    return { items: [], meta: {} };
  }
};

export const resetCart = async () => {
  await clearCart();
  const newCart = { items: [], meta: {} };
  setCart(newCart);
  return newCart;
};

export const clearCart = async () => {
  try {
    return await setItem(CART_KEY, []);
  } catch (error: any) {
    return { error: error.message };
  }
};

export const removeCartItem = async (id: string) => {
  try {
    const currentCart = (await getItem(CART_KEY)) as CartProps;
    if (currentCart) {
      const newCart = currentCart.items.filter((item: CartItemProps) => item.id !== id);
      if (newCart.length === 0) {
        await clearCart();
      } else {
        await setCart({ ...currentCart, items: newCart });
      }
    }
  } catch (error: any) {
    return { error: error.message };
  }
};

/**
 * Upsert cart item by id
 * This method can be used to insert or update cart item
 * @param cartItem
 * @returns CartProps
 */
export const upsertCartItem = async (cartItem: CartItemProps): Promise<CartProps | ErrorProps> => {
  try {
    let foundInCart = false;
    const currentCart = (await getItem(CART_KEY)) as CartProps;
    let _cart: CartProps | null = null;
    if (currentCart && currentCart.items) {
      const newCartItems = currentCart.items.map((item: CartItemProps) => {
        if (item.id === cartItem.id) {
          foundInCart = true;
          return cartItem;
        }
        return item;
      });
      if (!foundInCart) {
        newCartItems.push(cartItem);
      }
      _cart = { ...currentCart, items: newCartItems };
    } else {
      _cart = { items: [cartItem], meta: {} };
    }
    await setCart(_cart);
    return _cart;
  } catch (error: any) {
    throw error;
  }
};
