/**
 * This method returns the light, mid, and dark colors for a given theme color
 * @param param0
 */
export const getThemeColorObj = ({ color }: { color: string }) => {
  try {
    if (color) {
      return {
        light: colorShade(color, 32),
        mid: color,
        dark: colorShade(color, -32),
      };
    } else {
      return {};
    }
  } catch (error) {
    console.log('Error getThemeColorObj ~ error:', error);
    return {};
  }
};

/**
 * This method returns the light, mid, and dark colors for a given theme color
 * @param param0
 */
export const getThemeColorForSite = ({ primary, secondary, tertiary }: { primary?: string; secondary?: string; tertiary?: string }) => {
  try {
    let siteTheme = {};

    if (primary) {
      siteTheme = {
        primary: getThemeColorObj({ color: primary ?? '#171772' }),
      };
    }

    if (secondary) {
      siteTheme = {
        ...siteTheme,
        secondary: getThemeColorObj({ color: secondary ?? '#C800FF' }),
      };
    }

    if (tertiary) {
      siteTheme = {
        ...siteTheme,
        tertiary: getThemeColorObj({ color: tertiary ?? '#C800FF' }),
      };
    }

    return siteTheme;
  } catch (error) {
    console.log('Error getThemeColorForSite ~ error:', error);
    return null;
  }
};

const colorShade = (col: string, shade: number) => {
  col = col.replace(/^#/, '');
  if (col.length === 3) {
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];
  }

  const colorMatch = col.match(/.{2}/g);
  const r = Math.max(Math.min(255, parseInt(colorMatch?.[0] ?? '00', 16) + shade), 0).toString(16);
  const g = Math.max(Math.min(255, parseInt(colorMatch?.[1] ?? '00', 16) + shade), 0).toString(16);
  const b = Math.max(Math.min(255, parseInt(colorMatch?.[2] ?? '00', 16) + shade), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `#${rr}${gg}${bb}`;
};
