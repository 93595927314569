import React, { ReactNode } from 'react';

import { useTheme } from '@rugby-au/theme';

export const Container = ({ onKeyDown, containerStyles, children, ...props }: { onKeyDown?: any; containerStyles?: any; children: ReactNode }) => {
  const { spacing } = useTheme();

  // container css class used with media query to handle responsive breakpoints
  // couldnt use react native window dimensions as it doesnt work with nextjs SSR
  return (
    <div
      className="container"
      onKeyDown={onKeyDown}
      style={{
        flex: 1,
        position: 'relative',
        paddingLeft: spacing.large,
        paddingRight: spacing.large,
        marginLeft: 'auto',
        marginRight: 'auto',
        ...containerStyles,
      }}
      {...props}>
      {children}
    </div>
  );
};
