import React, { useEffect, useState } from 'react';

import { IColorGroup, ISizeGroup, ISpacing, ITheme, useTheme } from '@rugby-au/theme';
import { StyleSheet, View } from 'react-native';

import { useAppConfig } from '@rugby-au/app-config';
import { Text } from '@rugby-au/commons';

const PER_HOUR = 1000 * 60 * 60;
const PER_MINUTE = 1000 * 60;

const calculateCountDown = (expiry: number | undefined): string => {
  if (!expiry) {
    return '00:00';
  }
  const currentDate = new Date().getTime();
  const diff = expiry - currentDate;

  if (diff > 0) {
    var minutes = Math.floor((diff % PER_HOUR) / PER_MINUTE);
    var seconds = Math.floor((diff % PER_MINUTE) / 1000);

    return `${minutes >= 10 ? minutes : `0${minutes}`}:${seconds >= 10 ? seconds : `0${seconds}`}`;
  } else {
    return '00:00';
  }
};
export const CountdownNavItem = ({ appearance }: { appearance: ITheme; isHover: boolean | null; isMobile: boolean }) => {
  const [timeUnits, setTimeUnits] = useState<string>('' as string);
  const { cart } = useAppConfig();
  const { spacing, colorPairs, sizes } = useTheme();

  const colors = colorPairs[appearance];
  useEffect(() => {
    let timer: NodeJS.Timer | null = null;
    if (cart?.meta?.cartExpiry) {
      timer = setInterval(() => {
        setTimeUnits(() => calculateCountDown(cart?.meta?.cartExpiry));
      }, 1000);
    }

    return () => {
      if (timer !== null) {
        clearInterval(timer);
      }
    };
  }, [cart?.meta?.cartExpiry]);

  const styles = getStyles({ spacing, colors, sizes: sizes.xsmall });
  return <View>{timeUnits && timeUnits !== '' && <Text style={styles.countdown}>{timeUnits}</Text>}</View>;
};

const getStyles = ({ spacing, colors, sizes }: { spacing: ISpacing; colors: IColorGroup; sizes: ISizeGroup }) => {
  return StyleSheet.create({
    countdown: {
      padding: spacing.medium,
      backgroundColor: colors[':down'].foreground.mid,
      color: colors.foreground.mid,
      borderRadius: sizes.boxSize,
      width: 70,
      textAlign: 'center',
    },
  });
};
