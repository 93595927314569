import React from 'react';
import { Text } from '@rugby-au/commons';
import { useTheme } from '@rugby-au/theme';
import { getHeadingStyles } from './styles';

export const Headings = ({ title, level }: { title: string; level: string }) => {
  const { headings } = useTheme();
  const styles = getHeadingStyles({ headings, level });

  return <Text style={styles.container}>{`${title}`}</Text>;
};
