export const sampleFormConfig = {
  meta: {
    name: 'Sign Up',
    title: 'Sign Up',
    submitPath: '/api/v1/sign-up',
    submitMethod: 'POST',
    submitButton: { title: 'Submit', appearance: 'primary', fullWidth: true, weight: 'bold' },
  },
  fields: [
    { key: 'firstName', label: 'First Name', required: true, displayType: 'TextInput' },
    { key: 'lastName', label: 'Last Name', required: true, displayType: 'TextInput' },
    { key: 'dob', label: 'Date of birth', required: true, displayType: 'DateInput' },
    { key: 'country', label: 'Country', required: false, displayType: 'PickerInput', items: [], renderProp: 'en', valueProp: 'en' },
    { key: 'phoneNumber', label: 'Phone Number', required: true, displayType: 'PhoneInput' },
    { key: 'email', type: 'email-address', label: 'Email Address', required: true, displayType: 'TextInput', nationalConfig: { RAU: {}, USA: {} } },
    {
      key: 'rugbyRegion',
      displayType: 'PickerInput',
      items: [
        {
          label: 'Australia',
          value: 'RAU',
        },
        {
          label: 'United States',
          value: 'USA',
        },
      ],
      renderProp: 'label',
      valueProp: 'value',
      required: true,
      onUpdateAction: { type: 'appConfigRegion', key: 'nationalId', valueKey: 'value' },
    },
    {
      key: 'rauPrivacyPolicy',
      htmlLabel: 'I have read and agree to the <a href="https://www.rugby.com.au">Privacy Policy RAU</a>',
      required: true,
      displayType: 'Checkbox',
    },

    {
      key: 'usaPrivacyPolicy',
      htmlLabel: 'I have read and agree to the <a href="https://www.rugby.com.au">Privacy Policy USA</a>',
      required: true,
      displayType: 'Checkbox',
    },
  ],
  nationalConfig: {
    RAU: {
      skip: ['usaPrivacyPolicy'],
    },
    USA: {
      skip: ['rauPrivacyPolicy'],
    },
  },
};
